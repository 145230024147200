import { callApi } from 'utils/api'

export const setUniqueSessionToken = (type, token) => {
	const types = [
		'@token',
		'@super_admin_token',
		'@supplier_token',
		'@passenger_token',
		'@driver_token',
		'@client_token',
		'@employee_code',
	]

	types.forEach((tp) => {
		if (tp != type) {
			localStorage.removeItem(tp)
		} else {
			localStorage.setItem(type, token)
		}
	})
}

export const login = ({ username, password }) => {
	return async (dispatch, getState) => {
		try {
			const res = await callApi(`/app/login`, {
				method: 'POST',
				body: JSON.stringify({
					username,
					password,
				}),
			})

			if (res.status === 200) {
				try {
					const token = res.body.token
					setUniqueSessionToken('@token', token)

					dispatch({
						type: 'LOGIN',
						payload: res.body,
					})
				} catch (e) {
					// saving error
				}
			} else {
			}
		} catch (error) {
			console.log(error.message.error)
		}
	}
}

export const loginWithToken = (options) => {
	return async (dispatch, getState) => {
		try {
			const res = await callApi(`/app/login/token`, {
				method: 'POST',
				body: JSON.stringify({
					token: options.token,
				}),
			})

			if (res.status === 200) {
				try {
					const token = res.body.token

					setUniqueSessionToken('@token', token)
					dispatch({
						type: 'LOGIN',
						payload: res.body,
					})
					let state = getState()
					let list = []
					const res_visits = await callApi(`/user/last_visits`, {
						method: 'GET',
						headers: {
							Authorization: res.body.token,
						},
						params: {
							user_id: res.body.id,
							sys_company_id: res.body.sys_company_id,
						},
					})

					if (res_visits.body.response.length > 0) {
						dispatch({
							type: 'SET_LAST_VISIT_LIST',
							payload: res_visits.body.response,
						})
					} else {
						dispatch({
							type: 'INIT_LAST_VISITS_LIST',
						})
					}
				} catch (e) {
					// saving error
				}
			} else {
			}
		} catch (error) {
			console.log(error.message.error)
		}
	}
}

export const actionClick = (data) => {
	return async (dispatch, getState) => {
		let state = getState()
		let list = state?.app?.user?.last_visits

		if (!list) {
			list = []
		}
		if (!list.some((item) => item.name == data.name)) {
			if (list?.length == 5) {
				list = [data, ...list.slice(0, -1)]
			} else {
				list = [data, ...list]
			}
		}

		try {
			const res = await callApi(`/user/save_last_visits`, {
				method: 'POST',
				headers: {
					Authorization: state.app.user.token,
				},
				body: JSON.stringify({
					items: {
						user_id: state.app.user.user_id,
						sys_company_id: state.app.user.sys_company_id,
						last_visits: list,
					},
				}),
			})

			dispatch({
				type: 'SET_LAST_VISIT_LIST',
				payload: list,
			})
		} catch (e) {
			console.log(e)
		}
	}
}

export const logout = () => {
	try {
		localStorage.removeItem('@token')
		localStorage.removeItem('@employee_code')
	} catch (e) {
		// remove error
	}
	return {
		type: 'LOGOUT',
	}
}

export const updatePassword = (item) => {
	return async (dispatch, getState) => {
		try {
			const state = getState()
			const res = await callApi(`/user/change_password`, {
				method: 'POST',
				body: JSON.stringify(item),
				headers: {
					Authorization: state.app?.user?.token,
				},
				successMessage: 'Se actualizó correctamente',
			})

			dispatch({
				type: 'UPDATE_PASSWORD',
				payload: res.body,
			})
		} catch (error) {
			console.log(error.message.error)
		}
	}
}

export const wialonLogin = (item) => {
	return async (dispatch, getState) => {
		try {
			const state = getState()
			const res = await callApi(`/wialon/login`, {
				method: 'POST',
				body: JSON.stringify(item),
				headers: {
					Authorization: state.app?.user?.token,
				},
			})

			dispatch({
				type: 'LOGIN_WIALON',
				payload: res.body,
			})
		} catch (error) {
			console.log(error.message.error)
		}
	}
}

export const WialonLoginFromSuperAdmin = (item) => {
	return async (dispatch, getState) => {
		try {
			const state = getState()
			dispatch({
				type: 'LOADING_APP',
				payload: true,
			})
			const res = await callApi(`/wialon/login_from_super_admin`, {
				method: 'POST',
				body: JSON.stringify(item),
				headers: {
					token: state.app?.superAdmin?.token,
				},
			})
			if (res.status == 200) {
				dispatch({
					type: 'LOGIN_WIALON',
					payload: res.body,
				})

				if (res.body.au && item.sysCompanyId) {
					const arr = [...state.app?.superAdmin?.sysCompanies]

					const index = arr.findIndex((s) => s.id == item.sysCompanyId)

					arr[index] = {
						...arr[index],
						wialon_user_name: res.body.au,
					}

					dispatch({
						type: 'UPDATE_SYS_COMPANY_LIST',
						payload: arr,
					})
				}
			}
		} catch (error) {
			dispatch({
				type: 'ERROR_LOGIN_WIALON',
			})
			console.log(error.message.error)
		}
	}
}

export const authorizeChangeWialonUser = (credentials) => {
	return async (dispatch, getState) => {
		try {
			const state = getState()
			const res = await callApi(`/wialon/authorize_change_user`, {
				method: 'POST',
				body: JSON.stringify(credentials),
				headers: {
					Authorization: state.app?.user?.token,
				},
			})

			dispatch({
				type: 'CHANGE_USER_WIALON',
				payload: res.body,
			})

			return res.body
		} catch (error) {
			console.log(error.message.error)
		}
	}
}

export const getTimeZonesList = () => {
	return async (dispatch, getState) => {
		const state = getState()
		if (state.app?.superAdmin?.associate?.super_admin) {
			return null
		}
		const res = await callApi(`/utils/time_zones_list`, {
			method: 'GET',
			headers: {
				Authorization: state.app?.superAdmin?.token,
			},
		})

		dispatch({
			type: `GET_TIME_ZONES_LIST`,
			payload: res.body,
		})

		return res.body
	}
}

export const clientLogin = ({ code, password, identifier_code }) => {
	return async (dispatch, getState) => {
		try {
			const res = await callApi(`/app/login/client`, {
				method: 'POST',
				body: JSON.stringify({
					code,
					password,
					identifier_code,
				}),
			})

			if (res.status === 200) {
				try {
					const token = res.body.token
					setUniqueSessionToken('@client_token', token)

					dispatch({
						type: 'LOGIN_CLIENT',
						payload: res.body,
					})
				} catch (e) {
					// saving error
				}
			} else {
			}
		} catch (error) {
			console.log(error.message.error)
		}
	}
}

export const clientLoginWithToken = (options) => {
	return async (dispatch, getState) => {
		try {
			const res = await callApi(`/app/login/client/token`, {
				method: 'POST',
				body: JSON.stringify({
					token: options.client_token,
				}),
			})
			if (res.status === 200) {
				try {
					const token = res.body.token
					setUniqueSessionToken('@client_token', token)
					dispatch({
						type: 'LOGIN_CLIENT',
						payload: res.body,
					})
				} catch (e) {
					// saving error
				}
			} else {
			}
		} catch (error) {
			console.log(error.message.error)
		}
	}
}

export const clientLogout = () => {
	try {
		localStorage.removeItem('@client_token')
	} catch (e) {
		// remove error
	}
	return {
		type: 'CLIENT_LOGOUT',
	}
}

export const driverLogin = ({ user, password }) => {
	return async (dispatch, getState) => {
		try {
			const res = await callApi(`/app/login/driver`, {
				method: 'POST',
				body: JSON.stringify({
					user,
					password,
				}),
			})

			if (res.status === 200) {
				try {
					const token = res.body.token
					setUniqueSessionToken('@driver_token', token)

					dispatch({
						type: 'LOGIN_DRIVER',
						payload: res.body,
					})
				} catch (e) {
					// saving error
				}
			} else {
			}
		} catch (error) {
			console.log(error.message.error)
		}
	}
}
export const passengerLogin = ({ user, password }) => {
	return async (dispatch, getState) => {
		try {
			const res = await callApi(`/app/login/passenger`, {
				method: 'POST',
				body: JSON.stringify({
					user,
					password,
				}),
			})

			if (res.status === 200) {
				try {
					const token = res.body.token
					setUniqueSessionToken('@passenger_token', token)

					dispatch({
						type: 'LOGIN_PASSENGER',
						payload: res.body,
					})
				} catch (e) {
					// saving error
				}
			} else {
			}
		} catch (error) {
			console.log(error.message.error)
		}
	}
}
export const passengerLoginWithToken = (options) => {
	return async (dispatch, getState) => {
		try {
			const res = await callApi(`/app/login/passenger/token`, {
				method: 'POST',
				body: JSON.stringify({
					token: options.passenger_token,
				}),
			})
			if (res.status === 200) {
				try {
					const token = res.body.token
					setUniqueSessionToken('@passenger_token', token)
					dispatch({
						type: 'LOGIN_PASSENGER',
						payload: res.body,
					})
				} catch (e) {
					// saving error
				}
			} else {
			}
		} catch (error) {
			console.log(error.message.error)
		}
	}
}
export const driverLoginWithToken = (options) => {
	return async (dispatch, getState) => {
		try {
			const res = await callApi(`/app/login/driver/token`, {
				method: 'POST',
				body: JSON.stringify({
					token: options.driver_token,
				}),
			})
			if (res.status === 200) {
				try {
					const token = res.body.token
					setUniqueSessionToken('@driver_token', token)
					dispatch({
						type: 'LOGIN_DRIVER',
						payload: res.body,
					})
				} catch (e) {
					// saving error
				}
			} else {
			}
		} catch (error) {
			console.log(error.message.error)
		}
	}
}

export const driverLogout = () => {
	try {
		localStorage.removeItem('@driver_token')
	} catch (e) {
		// remove error
	}
	return {
		type: 'DRIVER_LOGOUT',
	}
}
export const passengerLogout = () => {
	try {
		localStorage.removeItem('@passenger_token')
	} catch (e) {
		// remove error
	}
	return {
		type: 'PASSENGER_LOGOUT',
	}
}

export const supplierLogin = ({ user, password }) => {
	return async (dispatch, getState) => {
		try {
			const res = await callApi(`/app/login/supplier`, {
				method: 'POST',
				body: JSON.stringify({
					email: user,
					password,
				}),
			})

			if (res.status === 200) {
				try {
					const token = res.body.token
					setUniqueSessionToken('@supplier_token', token)

					dispatch({
						type: 'LOGIN_SUPPLIER',
						payload: res.body,
					})
				} catch (e) {
					// saving error
				}
			} else {
			}
		} catch (error) {
			console.log(error.message.error)
		}
	}
}

export const supplierLoginWithToken = (options) => {
	return async (dispatch, getState) => {
		try {
			const res = await callApi(`/app/login/supplier/token`, {
				method: 'POST',
				body: JSON.stringify({
					token: options.supplier_token,
				}),
			})
			if (res.status === 200) {
				try {
					const token = res.body.token
					setUniqueSessionToken('@supplier_token', token)
					dispatch({
						type: 'LOGIN_SUPPLIER',
						payload: res.body,
					})
				} catch (e) {
					// saving error
				}
			} else {
			}
		} catch (error) {
			console.log(error.message.error)
		}
	}
}

export const supplierLogout = () => {
	try {
		localStorage.removeItem('@supplier_token')
	} catch (e) {
		// remove error
	}
	return {
		type: 'SUPPLIER_LOGOUT',
	}
}

export const excelLayout = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/${options.entity_name}/generate_layout_excel`, {
			method: 'PUT',
			headers: {
				Authorization: state.app.user.token,
			},
		})
			.then((res) => {
				let buff = Buffer.from(res.body)
				//res.body.blob();
				return buff
			})
			.then((blob) => {
				// Create blob link to download
				const url = window.URL.createObjectURL(new Blob([blob]))
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', `${options.entity_name}_layout.xlsx`)

				// Append to html link element page
				document.body.appendChild(link)

				// Start download
				link.click()

				// Clean up and remove the link
				link.parentNode.removeChild(link)
			})
	}
}

export const importFile = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(
			`/${options.entity_name}/import_data_from_excel`,
			{
				method: 'PUT',
				json: false,
				headers: {
					Authorization: state.app.user.token,
				},
				body: {
					file: options.files,
				},
			}
		)

		dispatch({
			type: 'IMPORT',
			payload: res.body,
		})

		return res.body
	}
}

export const verifyImportData = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(
			`/${options.entity_name}/validate_data_from_import`,
			{
				method: 'PUT',
				headers: {
					Authorization: state.app.user.token,
				},
				body: JSON.stringify(options.data),
			}
		)

		dispatch({
			type: 'IMPORT',
			payload: res.body,
		})

		return res.body
	}
}

export const setLanguage = (lang, setLocalStorage = true) => {
	return async (dispatch, getState) => {
		if (setLocalStorage) {
			await localStorage.setItem('@language', lang)
		}
		dispatch({ type: 'SET_LANGUAGE', payload: lang })
	}
}

export const associateLoginWithToken = (token) => {
	return async (dispatch, getState) => {
		try {
			const res = await callApi('/app/login/superadmin/token', {
				method: 'POST',
				body: JSON.stringify({
					token,
				}),
			})
			if (res.status == 200) {
				dispatch({ type: 'LOGIN_SUPER_ADMIN', payload: res.body })
			}
		} catch (e) {
			console.log('associateLoginWithToken', e.message)
		}
	}
}

export const associateLogout = () => {
	try {
		localStorage.removeItem('@super_admin_token')
	} catch (e) {
		// remove error
	}
	return {
		type: 'LOGOUT',
	}
}
