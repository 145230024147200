import crudHandlers from "./_crud";

export default (state, action) => {
  const {type} = action;
  switch (type) {

    default:

      state = crudHandlers(state, action, 'hr_category');

      return state
  }
}