import crudHandlers from './_crud'

export default (state, action) => {
	const { type } = action
	switch (type) {
		case 'LOAD_INSPECTION_ADDITIONAL_COMMENTS': {
			let { response, payload } = action

			return {
				...state,
				comments: payload.inspection_additional_comments,
			}
		}

		default:
			state = crudHandlers(state, action, 'service_order')

			return state
	}
}
