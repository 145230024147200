import crudHandlers from './_crud'

export default (state, action) => {
	const { type } = action
	switch (type) {
		case 'LOAD_ARTICLES_FROM_REQUISITION': {
			var { response, payload } = action
			return {
				...state,
				is_fetching: false,
				articles_from_requisition: response,
				current_requisition: payload,
			}
		}
		case 'SYNC_REQUISITION_ARTICLES': {
			var { response, payload } = action
			var { item, deletedItems, createdItems, updateItems } = response

			return {
				...state,
				is_fetching: false,
				articles_sync: { deletedItems, createdItems, updateItems },
				consignment_letter: item,
			}
		}

		default:
			state = crudHandlers(state, action, 'requisition')

			return state
	}
}
