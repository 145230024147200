import { Button, Checkbox, Icon, Input, Select } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import {
	geClientRouteList,
	loadArticlesList,
	loadBranchOfficesList,
	loadClientsList,
	loadCompaniesList,
	loadCustomCatalogsInstancesList,
	loadEmployeesList,
	loadSuppliersList,
	loadTrailersList,
	loadUnitsList,
} from '../utilities'
import { useState } from 'react'
import { showErrorMessage } from 'utils/messages'
import Dropzone from 'react-dropzone'
import SignatureCanvas from 'react-signature-canvas'
import * as pako from 'pako'

const styles = {
	extend: {
		width: '98%',
	},
	flexColumn: {
		display: 'flex',
		flexDirection: 'column',
	},
	gap10: {
		gap: '10px',
	},
}

export function getQuestionValuesByKey(element) {
	const questionId = element.id
	const answerId = element?.answer_data?.id

	return {
		answerId,
		questionId,
	}
}

const handleChange = (question, editHiddensList, value) => {
	let { questionId, answerId } = getQuestionValuesByKey(question)

	let editValues = { question_id: questionId, answerId: answerId, value: value }

	// Send to 'action' then it changes the 'hiddens list' stored in redux
	editHiddensList(editValues)
}

const CheckOblitagory = ({ question }) => {
	return (
		<div
			style={{
				marginTop: '5px',
				display: `${question.is_obligatory && question.answer == '' ? 'block' : 'none'}`,
				fontWeight: 'bold',
				color: 'red',
				fontSize: '12px',
			}}
		>
			* Obligatorio responder
		</div>
	)
}

export const TypeText = ({ question, editHiddensList }) => {
	return (
		<div style={{ display: 'flex', flexDirection: 'column', gap: '10' }}>
			{question.visible_only_report_detail_was_answered ? (
				<span>{question.answer}</span>
			) : (
				<>
					<Input
						value={question?.answer || question?.prev_answer}
						style={{ width: '98%' }}
						placeholder='escribe aqui'
						onChange={({ target }) => handleChange(question, editHiddensList, target.value)}
					/>
					<CheckOblitagory question={question} />
				</>
			)}
		</div>
	)
}

export const TypeNumber = ({ question, editHiddensList }) => {
	return (
		<div>
			{question.visible_only_report_detail_was_answered ? (
				<span>{question.answer}</span>
			) : (
				<div>
					<Input
						value={question?.answer || question?.prev_answer}
						type='number'
						style={{ width: '98%' }}
						placeholder='0'
						onChange={({ target }) => handleChange(question, editHiddensList, target.value)}
					/>
					<CheckOblitagory question={question} />
				</div>
			)}
		</div>
	)
}

export const TypeDate = ({ question, editHiddensList }) => {
	return (
		<div>
			{question.visible_only_report_detail_was_answered ? (
				<span>{question.answer}</span>
			) : (
				<>
					<Input
						value={question.answer || question?.prev_answer}
						type='date'
						style={{ width: '98%' }}
						placeholder='0'
						onChange={({ target }) => handleChange(question, editHiddensList, target.value)}
					/>
					<CheckOblitagory question={question} />
				</>
			)}
		</div>
	)
}

export const TypeDateTime = ({ question, editHiddensList }) => {
	return (
		<div>
			{question.visible_only_report_detail_was_answered ? (
				<span>{question.answer}</span>
			) : (
				<>
					<input
						type='time'
						value={question.answer || question?.prev_answer}
						onChange={({ target }) => handleChange(question, editHiddensList, target.value)}
					/>
					<CheckOblitagory question={question} />
				</>
			)}
		</div>
	)
}

export const TypeShowImage = ({ question, editHiddensList }) => {
	return (
		<div>
			{question.visible_only_report_detail_was_answered ? (
				<span>{question.answer}</span>
			) : (
				<div>Show image</div>
			)}
		</div>
	)
}

export const TypeSelect = ({ question, editHiddensList }) => {
	let wasAnswered = question.visible_only_report_detail_was_answered

	let foundAnswer
	if (wasAnswered) {
		foundAnswer = question?.answer?.find((item) => item.value)
	}

	return (
		<div style={{ ...styles.flexColumn, ...styles.gap10 }}>
			{question.visible_only_report_detail_was_answered ? (
				<span>{foundAnswer.option}</span>
			) : (
				<>
					<Select
						value={
							typeof question.answer == 'array'
								? question?.answer?.find((item) => item.value)?.option
								: ''
						}
						placeholder='Seleccione una opción de la lista'
						onChange={(_, { value }) => {
							let resultValue = question.answer_options.map((item) => {
								return item.option == value
									? {
											...item,
											value: true,
									  }
									: {
											...item,
											value: false,
									  }
							})

							handleChange(question, editHiddensList, resultValue)
						}}
						options={question.answer_options.map((item) => ({
							value: item.option,
							key: item.option,
							text: item.option,
						}))}
					/>
					<CheckOblitagory question={question} />
				</>
			)}
		</div>
	)
}

export const TypeCheckbox = ({ question, editHiddensList }) => {
	let wasAnswered = question.visible_only_report_detail_was_answered

	let foundAnswer
	if (wasAnswered) {
		foundAnswer = question.answer.filter((item) => item.value)
	}
	return (
		<div style={{ ...styles.flexColumn, ...styles.gap10 }}>
			{question.visible_only_report_detail_was_answered ? (
				<span>{foundAnswer[0].option}</span>
			) : (
				<>
					{question.answer_options.map((item) => {
						let isChecked = Array.isArray(question.answer)
							? question.answer.find((option) => {
									return option.option == item.option
							  })?.value
							: false

						return (
							<div>
								<Checkbox
									checked={isChecked}
									label={item.option}
									onChange={({ target }) => {
										let resultValue
										if (question.answer == '') {
											resultValue = question.answer_options.map((option) => {
												return item.option == option.option
													? {
															...option,
															value: !option.value,
													  }
													: {
															...option,
													  }
											})
										} else {
											resultValue = question.answer.map((option) => {
												return option.option == item.option
													? {
															...option,
															value: !option.value,
													  }
													: {
															...option,
													  }
											})
										}

										if (question.answer != '' && !resultValue.some((item) => item.value)) {
											resultValue = ''
										}

										handleChange(question, editHiddensList, resultValue)
									}}
								/>
							</div>
						)
					})}
					<CheckOblitagory question={question} />
				</>
			)}
		</div>
	)
}

export const TypeRadio = ({ question, editHiddensList }) => {
	let wasAnswered = question.visible_only_report_detail_was_answered

	let foundAnswer
	if (wasAnswered) {
		foundAnswer = question.answer.find((item) => item.value)
	}
	return (
		<div style={{ ...styles.flexColumn, ...styles.gap10 }}>
			{question.visible_only_report_detail_was_answered ? (
				<span>{foundAnswer.option}</span>
			) : (
				<>
					{question.answer_options.map((item) => {
						let isChecked = Array.isArray(question.answer)
							? question.answer.find((option) => {
									return option.option == item.option
							  })?.value
							: false
						return (
							<div>
								<Checkbox
									radio
									checked={isChecked}
									label={item.option}
									onChange={() => {
										let resultValue
										if (question.answer == '') {
											resultValue = question.answer_options.map((option) => {
												return item.option == option.option
													? {
															...option,
															value: true,
													  }
													: {
															...option,
															value: false,
													  }
											})
										} else {
											resultValue = question.answer.map((option) => {
												return option.option == item.option
													? {
															...option,
															value: true,
													  }
													: {
															...option,
															value: false,
													  }
											})
										}

										handleChange(question, editHiddensList, resultValue)
									}}
								/>
							</div>
						)
					})}

					<CheckOblitagory question={question} />
				</>
			)}
		</div>
	)
}

export const TypeSignature = ({ question, editHiddensList }) => {
	const [sign, setSign] = useState()

	if (question.get_from_detail && question.visible_only_report_detail_was_answered) {
		if (question.answer.image_url.preview) {
			return (
				<img
					style={{ width: '200px', height: '200px', objectFit: 'contain' }}
					src={question.answer.image_url.preview}
					alt={question.question_description_key}
				/>
			)
		} else {
			return <span>No se adjuntó firma</span>
		}
	}

	return (
		<div style={{ ...styles.flexColumn, ...styles.gap10 }}>
			<div style={{ border: '1px solid #d8d8d8', width: '500px', borderRadius: '5px' }}>
				<SignatureCanvas
					canvasProps={{
						width: 500,
						height: 150,
					}}
					ref={(data) => setSign(data)}
					onBegin={() => {}}
				/>
			</div>

			<div style={{ display: 'flex', gap: '5px' }}>
				<Button
					onClick={(e) => {
						e.preventDefault()
						sign.clear()

						let value = ''

						handleChange(
							{ ...question, visible_only_report_detail_was_answered: false },
							editHiddensList,
							value
						)
					}}
				>
					Limpiar
				</Button>

				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						gap: '5px',
						alignItems: 'center',
					}}
				>
					<Button
						color='green'
						onClick={(e) => {
							e.preventDefault()
							const stringBase64 = sign.getTrimmedCanvas().toDataURL('image/png', 1.0)

							const parts = stringBase64.split(',')
							const contentType = parts[0].split(':')[1]
							const base64Data = parts[1]

							const decodedData = atob(base64Data)

							// Crear un ArrayBuffer a partir de los bytes decodificados
							const arrayBuffer = new ArrayBuffer(decodedData.length)
							const view = new Uint8Array(arrayBuffer)
							for (let i = 0; i < decodedData.length; i++) {
								view[i] = decodedData.charCodeAt(i)
							}

							// Crear un Blob a partir del ArrayBuffer y el tipo de contenido
							const blob = new Blob([arrayBuffer], {
								type: contentType,
							})

							// Crear una URL a partir del Blob
							const imageUrl = URL.createObjectURL(blob)

							// Convertir el string base64 a un ArrayBuffer o Uint8Array
							const decodedDataBuffer = Buffer.from(stringBase64, 'base64')

							// Comprimir el dato
							const compressedData = pako.deflate(decodedDataBuffer)

							// Opcionalmente, si necesitas que la salida sea nuevamente un string en formato base64:
							const compressedBase64 = Buffer.from(compressedData).toString('base64')

							let resultValue = {
								preview: imageUrl,
								signature: compressedBase64,
							}

							handleChange(
								{ ...question, visible_only_report_detail_was_answered: true },
								editHiddensList,
								resultValue
							)
						}}
					>
						Aceptar
					</Button>

					{question?.answer?.signature && (
						<p
							style={{
								fontWeight: 'bold',
								color: 'green',
							}}
						>
							La firma se ha adjuntado
						</p>
					)}
				</div>
			</div>

			<CheckOblitagory
				question={{
					...question,
					answer: question?.answer?.image_url == '' ? '' : question?.answer,
				}}
			/>
		</div>
	)
}

export const TypeImage = ({ question, editHiddensList }) => {
	if (question.get_from_detail && question.visible_only_report_detail_was_answered) {
		if (question?.prev_answer?.image_url) {
			return (
				<img
					style={{ width: '200px', height: '200px', objectFit: 'contain' }}
					src={question.prev_answer.image_url.preview}
				/>
			)
		}

		if (question.answer.image_url.preview) {
			return (
				<img
					style={{ width: '200px', height: '200px', objectFit: 'contain' }}
					src={question.answer.image_url.preview}
				/>
			)
		} else {
			return <span>No se adjuntó imagen</span>
		}
	}

	if (question.visible_only_report_detail_was_answered) {
		return (
			question?.answer?.preview &&
			question.answer.preview.map((item) => {
				return <img style={{ width: '200px' }} src={item.preview} alt={item.name} key={item.name} />
			})
		)
	}
	return (
		<div
			style={{
				...styles.d_flex,
				...styles.justify_content_between,
				alignItems: 'center',
			}}
		>
			{question?.answer?.preview &&
				question.answer.preview.map((item) => {
					return (
						<img style={{ width: '200px' }} src={item.preview} alt={item.name} key={item.name} />
					)
				})}

			<Dropzone
				onDrop={async (files) => {
					if (files.length > 0) {
						let newPhoto = files.map((e) => {
							return Object.assign(e, {
								preview: URL.createObjectURL(e),
								id: Math.random().toString(30).substring(2),
							})
						})

						let resultValue = {
							preview: newPhoto,
							meta_data: {
								name: newPhoto[0].name,
								size: newPhoto[0].size,
							},
						}
						handleChange(question, editHiddensList, resultValue)
					} else {
						showErrorMessage({
							error: 'Solamente se aceptan los formatos: PNG, JPG, JPEG',
						})
					}
				}}
			>
				{({ getRootProps, getInputProps }) => (
					<div
						{...getRootProps()}
						style={{
							marginTop: '10px',
							marginBottom: '12px',
							width: 'max-content',
							display: `${question.visible_only_report_detail_was_answered ? 'none' : 'block'}`,
						}}
					>
						<input {...getInputProps()} />
						<Button
							style={{
								padding: '15px 10px',
								background: '#3ddd62',
								color: '#fff',
							}}
							key={`dropzone-file-upload`}
							type='button'
							className={
								'mini ui right labeled icon button' +
								' ' +
								`${styles.Bordered}` +
								' ' +
								styles.IconButton +
								' ' +
								`${styles['pulse-grow-on-hover']}`
							}
						>
							<Icon name='upload' />
							Adjuntar Imagen
						</Button>
					</div>
				)}
			</Dropzone>
			<CheckOblitagory
				question={{ ...question, answer: question?.answer?.image_url == '' ? '' : question.answer }}
			/>
		</div>
	)
}

export const TypeEmployees = ({ question, editHiddensList }) => {
	const [data, setData] = useState([])

	useEffect(() => {
		loadEmployeesList(question.userType).then((data) => setData(data))
	}, [question])

	return (
		<div>
			{question.visible_only_report_detail_was_answered ? (
				<span>{question.answer_hidden[0]?.employee_name}</span>
			) : (
				<>
					<Select
						style={{ ...styles.extend }}
						value={question.answer[0]?.employee_id}
						onChange={(_, { value }) => {
							let employee_selected = data.find((item) => item.id == value)

							let resultValue = [
								{
									selected: true,
									employee_id: value,
									employee_name: employee_selected.name,
								},
							]

							handleChange(question, editHiddensList, resultValue)
							//[{"selected": true, "employee_id": 618, "employee_name": "Edgar Conductor Ruelas Martinez"}]
						}}
						placeholder='Selecciona Empleado'
						options={data.map((item) => {
							return {
								value: item.id,
								text: item.full_name,
								key: item.full_name,
							}
						})}
					/>
					<CheckOblitagory question={question} />
				</>
			)}
		</div>
	)
}

export const TypeBranchOffice = ({ question, editHiddensList }) => {
	const [data, setData] = useState([])

	useEffect(() => {
		loadBranchOfficesList(question.userType).then((data) => setData(data))
	}, [question])

	return (
		<div>
			{question.visible_only_report_detail_was_answered ? (
				<span>{question.answer_hidden[0].branch_office_name}</span>
			) : (
				<>
					<Select
						style={{ ...styles.extend }}
						value={question.answer[0]?.branch_office_id}
						onChange={(_, { value }) => {
							let branch_office_selected = data.find((item) => item.id == value)

							let resultValue = [
								{
									selected: true,
									branch_office_id: value,
									branch_office_name: branch_office_selected.name,
								},
							]

							handleChange(question, editHiddensList, resultValue)
							//[{"selected": true, "branch_office_id": 7, "branch_office_name": "Sucursal 2"}]
						}}
						placeholder='Selecciona Sucursal'
						options={data.map((item) => {
							return {
								value: item.id,
								text: item.name,
								key: item.name,
							}
						})}
					/>
					<CheckOblitagory question={question} />
				</>
			)}
		</div>
	)
}

export const TypeCustomCatalog = ({ question, editHiddensList }) => {
	const [data, setData] = useState([])

	useEffect(() => {
		loadCustomCatalogsInstancesList(question.userType, question.form_data.form_id).then((data) =>
			setData(data)
		)
	}, [question])

	return (
		<div>
			{question.visible_only_report_detail_was_answered ? (
				<span>{question.answer_hidden[0].custom_catalog_instance_name}</span>
			) : (
				<>
					<Select
						style={{ ...styles.extend }}
						value={question.answer[0]?.custom_catalog_instance_id}
						onChange={(_, { value }) => {
							let custom_catalog_selected = data.find((item) => item.id == value)

							let resultValue = [
								{
									selected: true,
									custom_catalog_instance_id: value,
									custom_catalog_instance_name: custom_catalog_selected.name,
								},
							]

							handleChange(question, editHiddensList, resultValue)
						}}
						placeholder='Selecciona Catálogo Personalizado'
						options={data.map((item) => {
							return {
								value: item.id,
								text: item.name,
								key: item.name,
							}
						})}
					/>
					<CheckOblitagory question={question} />
				</>
			)}
		</div>
	)
}

export const TypeRoute = ({ question, editHiddensList }) => {
	const [data, setData] = useState([])

	useEffect(() => {
		geClientRouteList(question.userType).then((data) => setData(data))
	}, [question])

	return (
		<div>
			{question.visible_only_report_detail_was_answered ? (
				<span>{question.answer_hidden[0].route_name}</span>
			) : (
				<>
					<Select
						style={{ ...styles.extend }}
						value={question.answer[0]?.route_id}
						onChange={(_, { value }) => {
							let route_selected = data.find((item) => item.id == value)

							let resultValue = [
								{
									selected: true,
									route_id: value,
									route_name: route_selected.name,
									name: route_selected.name,
								},
							]

							handleChange(
								{ ...question, visible_only_report_detail_was_answered: true },
								editHiddensList,
								resultValue
							)
							//[{"name": "Ruta 1", "route_id": 1246, "selected": true, "route_name": "Ruta 1"}]
						}}
						placeholder='Selecciona Ruta'
						options={data.map((item) => {
							return {
								value: item.id,
								text: item.name,
								key: item.name,
							}
						})}
					/>
					<CheckOblitagory question={question} />
				</>
			)}
		</div>
	)
}

export const TypeUnits = ({ question, editHiddensList }) => {
	const [data, setData] = useState([])

	useEffect(() => {
		loadUnitsList(question.userType).then((data) => setData(data))
	}, [question])

	return (
		<div>
			{question.visible_only_report_detail_was_answered ? (
				<span>{question.answer_hidden[0]?.unit_name}</span>
			) : (
				<>
					<Select
						style={{ ...styles.extend }}
						value={question.answer[0]?.unit_id}
						onChange={(_, { value }) => {
							let unit_selected = data.find((item) => item.id == value)

							let resultValue = [
								{
									selected: true,
									unit_id: value,
									unit_name: unit_selected.name,
									wialon_id: unit_selected.wialon_id,
									service_order: false,
								},
							]

							handleChange(question, editHiddensList, resultValue)
						}}
						placeholder='Selecciona Unidad'
						options={data.map((item) => {
							return {
								value: item.id,
								text: item.name,
								key: item.name,
							}
						})}
					/>
					<CheckOblitagory question={question} />
				</>
			)}
		</div>
	)
}

export const TypeClients = ({ question, editHiddensList }) => {
	const [data, setData] = useState([])

	useEffect(() => {
		loadClientsList(question.userType).then((data) => setData(data))
	}, [question])

	return (
		<div>
			{question.visible_only_report_detail_was_answered ? (
				<span>{question.answer_hidden[0]?.client_name}</span>
			) : (
				<>
					<Select
						style={{ ...styles.extend }}
						value={question.answer[0]?.client_id}
						onChange={(_, { value }) => {
							let client_selected = data.find((item) => item.id == value)

							let resultValue = [
								{
									selected: true,
									client_id: value,
									client_name: client_selected.name,
								},
							]

							handleChange(question, editHiddensList, resultValue)
						}}
						placeholder='Selecciona Cliente'
						options={data.map((item) => {
							return {
								value: item.id,
								text: item.name,
								key: item.name,
							}
						})}
					/>
					<CheckOblitagory question={question} />
				</>
			)}
		</div>
	)
}

export const TypeSuppliers = ({ question, editHiddensList }) => {
	const [data, setData] = useState([])

	useEffect(() => {
		loadSuppliersList(question.userType).then((data) => setData(data))
	}, [question])

	return (
		<div>
			{question.visible_only_report_detail_was_answered ? (
				<span>{question.answer_hidden[0]?.supplier_name}</span>
			) : (
				<>
					<Select
						style={{ ...styles.extend }}
						value={question.answer[0]?.supplier_id}
						onChange={(_, { value }) => {
							let supplier_selected = data.find((item) => item.id == value)

							let resultValue = [
								{
									selected: true,
									supplier_id: value,
									supplier_name: supplier_selected.name,
								},
							]

							handleChange(question, editHiddensList, resultValue)
						}}
						placeholder='Selecciona Proveedor'
						options={data.map((item) => {
							return {
								value: item.id,
								text: item.name,
								key: item.name,
							}
						})}
					/>
					<CheckOblitagory question={question} />
				</>
			)}
		</div>
	)
}

export const TypeTrailers = ({ question, editHiddensList }) => {
	const [data, setData] = useState([])

	useEffect(() => {
		loadTrailersList(question.userType).then((data) => setData(data))
	}, [question])

	return (
		<div>
			{question.visible_only_report_detail_was_answered ? (
				<span>{question.answer_hidden[0]?.trailer_name}</span>
			) : (
				<>
					<Select
						style={{ ...styles.extend }}
						value={question.answer[0]?.trailer_id}
						onChange={(_, { value }) => {
							let trailer_selected = data.find((item) => item.id == value)

							let resultValue = [
								{
									selected: true,
									trailer_id: value,
									trailer_name: trailer_selected.name,
								},
							]

							handleChange(question, editHiddensList, resultValue)
						}}
						placeholder='Selecciona Remolque'
						options={data.map((item) => {
							return {
								value: item.id,
								text: item.name,
								key: item.name,
							}
						})}
					/>
					<CheckOblitagory question={question} />
				</>
			)}
		</div>
	)
}

export const TypeCompanies = ({ question, editHiddensList }) => {
	const [data, setData] = useState([])

	useEffect(() => {
		loadCompaniesList(question.userType).then((data) => setData(data))
	}, [question])

	return (
		<div>
			{question.visible_only_report_detail_was_answered ? (
				<span>{question.answer_hidden[0]?.company_name}</span>
			) : (
				<>
					<Select
						style={{ ...styles.extend }}
						value={question.answer[0]?.company_id}
						onChange={(_, { value }) => {
							let company_selected = data.find((item) => item.id == value)

							let resultValue = [
								{
									selected: true,
									company_id: value,
									company_name: company_selected.name,
								},
							]

							handleChange(question, editHiddensList, resultValue)
						}}
						placeholder='Selecciona Empresa'
						options={data.map((item) => {
							return {
								value: item.id,
								text: item.name,
								key: item.name,
							}
						})}
					/>
					<CheckOblitagory question={question} />
				</>
			)}
		</div>
	)
}

export const TypeArticles = ({ question, editHiddensList }) => {
	const [data, setData] = useState([])

	useEffect(() => {
		loadArticlesList(question.userType).then((data) => setData(data))
	}, [question])

	return (
		<div>
			{question.visible_only_report_detail_was_answered ? (
				<span>{question.answer_hidden[0]?.article_description}</span>
			) : (
				<>
					<Select
						style={{ ...styles.extend }}
						value={question.answer[0]?.article_id}
						onChange={(_, { value }) => {
							let article_selected = data.find((item) => item.id == value)

							let resultValue = [
								{
									selected: true,
									article_id: value,
									article_description: article_selected.description_spanish,
								},
							]

							handleChange(question, editHiddensList, resultValue)
						}}
						placeholder='Selecciona Empresa'
						options={data.map((item) => {
							return {
								value: item.id,
								text: item.description_spanish,
								key: item.description_spanish,
							}
						})}
					/>
					<CheckOblitagory question={question} />
				</>
			)}
		</div>
	)
}

const questionPropTypes = {
	question: PropTypes.object.isRequired,
	editHiddensList: PropTypes.func.isRequired,
}

TypeText.propTypes = questionPropTypes
TypeNumber.propTypes = questionPropTypes
TypeDate.propTypes = questionPropTypes
TypeDateTime.propTypes = questionPropTypes
TypeShowImage.propTypes = questionPropTypes
TypeSelect.propTypes = questionPropTypes
TypeCheckbox.propTypes = questionPropTypes
TypeRadio.propTypes = questionPropTypes
TypeSignature.propTypes = questionPropTypes
TypeImage.propTypes = questionPropTypes
TypeEmployees.propTypes = questionPropTypes
TypeBranchOffice.propTypes = questionPropTypes
TypeCustomCatalog.propTypes = questionPropTypes
TypeRoute.propTypes = questionPropTypes
TypeUnits.propTypes = questionPropTypes
TypeClients.propTypes = questionPropTypes
TypeSuppliers.propTypes = questionPropTypes
TypeTrailers.propTypes = questionPropTypes
TypeCompanies.propTypes = questionPropTypes
TypeArticles.propTypes = questionPropTypes
