import React, { useEffect, useRef } from "react";
import PaginatedTable from 'semantic-table/dist/components/PaginatedTable';

const CustomPaginatedTable = (props) => {
  const tableRef = useRef(null);

  useEffect(() => {
    const applyNotranslate = () => {
      if (tableRef.current) {
        const tds = tableRef.current.querySelectorAll('td');
        tds.forEach(td => td.classList.add('notranslate'));
      }
    };

    const timeoutId = setTimeout(applyNotranslate, 0);

    return () => clearTimeout(timeoutId);
  }, [props.rows]);

  const customRenderCell = (row, column) => {
    return (
      <td className="notranslate">
        {column.render ? column.render(row) : row[column.accessor]}
      </td>
    );
  };

  const customColumns = props.columns.map(column => ({
    ...column,
    Cell: (row) => customRenderCell(row, column)
  }));

  return (
    <div ref={tableRef}>
      <PaginatedTable
        {...props}
        columns={customColumns}
      />
    </div>
  );
};

export default CustomPaginatedTable;
