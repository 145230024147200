import crudHandlers from "./_crud";

export default (state, action) => {
    const { type } = action;
    switch (type) {
        case 'ADD_SYNC_ORIGIN_DESTINATION':
        case 'UPDATE_SYNC_ORIGIN_DESTINATION': {
			var { response } = action
			return {
				...state,
				entities: {
                    ...state.entities,
                    [response.id]:response
                }
			}
		}
        default:

            state = crudHandlers(state, action, 'origin_destination')

            return state
    }
}