import crud from './_crud'
import { callApi } from 'utils/api'
import { showErrorMessage, showSuccessMessage } from 'utils/messages'
import Swal from 'sweetalert2'

const actions = crud('storage', 'el almacen', 'los almacenes')

const getReportMaxMin = (options) => {
	return async (dispatch, getState) => {
		const state = getState()
		let articlesFilter = options?.articlesIds
		let storageFilter = options?.storageId
		const res = await callApi(`/storage/reports/max_min`, {
			method: 'GET',
			headers: {
				Authorization: state.app.user.token,
			},
			params: {
				articlesIds: JSON.stringify(articlesFilter),
				storageId: JSON.stringify(storageFilter),
				groupBy: options?.groupBy,
			},
		})
		return res.body
	}
}

const updateStorage = (item, options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/storage/update_storages`, {
			method: 'POST',
			headers: {
				Authorization: state.app.user.token,
			},
			body: JSON.stringify({
				createItems: item.createItems,
				deleteItems: item.deleteItems,
				updateItems: item.updateItems,
			}),
			successMessage: `Se agregó los almacenes correctamente.`,
		})
		showSuccessMessage('Los almacenes se registrarón correctamente')
		return res.body
	}
}

const saveRights = ({ storageIds, userRights }) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/storage/save_rights`, {
			method: 'POST',
			headers: {
				Authorization: state.app.user.token,
			},
			body: JSON.stringify({
				storageIds,
				userRights,
			}),
		})

		// showSuccessMessage('Los derechos se han actualizado correctamente.')
		Swal.fire({
			title: '¡Acción Realizada!',
			text: 'Los derechos se han actualizado correctamente.',
			icon: 'success',
			confirmButtonText: 'OK',
		}).then((result) => {
			if (result.isConfirmed) {
				window.location.reload()
			}
		})
		return res.body
	}
}

const loadStorages = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/storage/utils/get_storage_rights`, {
			method: 'GET',
			headers: {
				Authorization: state.app.user.token,
			},
			params: options.params,
		})

		return res.body
	}
}

const loadRequisitionArticles = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/storage/utils/load_requisition_articles`, {
			method: 'GET',
			headers: {
				Authorization: state.app.user.token,
			},
			params: options,
		})

		return res.body
	}
}

export default {
	...actions,
	getReportMaxMin,
	updateStorage,
	saveRights,
	loadStorages,
	loadRequisitionArticles,
}
