import crudHandlers from './_crud'

export default (state, action) => {
	const { type } = action
	switch (type) {
		case 'UNIT_CURR_CLIENT_ROUTE': {
			var { response, payload } = action

			return {
				...state,
				is_fetching: false,
				unit_current: response,
			}
		}

		case 'LOAD_CLIENT_ROUTE_STATUS': {
			var { response, payload } = action

			return {
				...state,
				is_fetching: false,
				route_status: response,
			}
		}
		case 'GET_ALL_CLIENT_ROUTE_BY_USERTYPE': {
			return {
				...state,
				entities: action.payload,
			}
		}
		default:
			state = crudHandlers(state, action, 'client_route')

			return state
	}
}
