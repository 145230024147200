import crudHandlers from "./_crud";

export default (state, action) => {
    const { type, payload } = action;
    switch (type) {

        case 'GET_LAST_DATA_SUCCESS':
            const newData = payload.data;
            console.log('payload', payload)
            return {
                ...state,
                entities: newData,
            };

        default:

            state = crudHandlers(state, action, 'tour_checkpoint_logs')

            return state
    }
}