import crudHandlers from './_crud';

// Importa las acciones relacionadas con el logo token
import {
  LOGO_TOKEN_VALIDATED_SUCCESS,
  LOGO_TOKEN_VALIDATED_ERROR,
} from '../actions/sys_companys'; 

export default (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOGO_TOKEN_VALIDATED_SUCCESS:
      return {
        ...state,
      };

    case LOGO_TOKEN_VALIDATED_ERROR:
      return {
        ...state,
      };

    default:
      state = crudHandlers(state, action, 'sys_company');
      return state;
  }
};
