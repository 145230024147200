import crud from "./_crud"
import { callApi } from "utils/api"
 
const entity = 'document_item'
const entityMsg = 'Documento'

const addDocumentItem = (item, file) => {
	return async (dispatch, getState) => {
		const state = getState()

		let res = await callApi(`/${entity}/addDocumentItem/`, {
			json: false,
			method: 'POST',
			headers: {
				Authorization: state.app.user.token,
			},
			body: {
				item: JSON.stringify(item),
				files: file?.content ?? file
			},
			successMessage: `Se agregó el documento correctamente.`,
		})
	}
}

const updateDocumentItem = (item, images, file) => {
	return async (dispatch, getState) => {
		const state = getState()

		delete item.has_attachment

		let res = await callApi(`/${entity}/updateDocumentItem/${item.id}`, {
			json: false,
			method: 'POST',
			body: {
				item: JSON.stringify(item),
			    images, // antigua
				files: file?.content ?? file
			},
			headers: {
				Authorization: state.app.user.token,
			},
			successMessage: `Se actualizó el documento correctamente.`,
		})

		let stored_item = res.body

		dispatch({
			type: `UPDATE_DOCUMENT_ITEM`,
			response: stored_item,
		})
	}
}

const loadExpirationReport = () => {
    return async (dispatch, getState) => {
      const state = getState()
  
      const res = await callApi(`/${entity}/utils/get_expiration_report`, {
        method: 'GET',
        headers: {
          Authorization: state.app.user.token
        }
      })
  
      dispatch({
        type: `LOAD_REPORT_${entity.toUpperCase()}`,
        response: res.body
      })
  
      return res.body
    }
}

let customActions = {}
customActions = {...customActions,loadExpirationReport, updateDocumentItem, addDocumentItem}
const actions = {...crud(entity, 'el documento', 'los documentos'), ...customActions}

export default actions