import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

const PublicDriverRoute = ({
	isAuthenticated,
	component: Component,
	driver,
	...rest
}) => {
	return (
		<Route
			{...rest}
			component={(props) =>
				isAuthenticated && driver?.sys_module[0] ? (
					<Redirect to={driver?.sys_module[0]?.url} />
				) : isAuthenticated && !driver?.sys_module[0] ? (
					<Redirect to={'/driver/driver_inspection_page'} />
				) : (
					<Component {...props} />
				)
			}
		/>
	)
}

const mapStateToProps = (state) => ({
	driver: state.app.driver,
	isAuthenticated: !!state.app.driver,
})

export default connect(mapStateToProps)(PublicDriverRoute)
