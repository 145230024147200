import crudHandlers from './_crud'

export default (state, action) => {
	const { type, payload } = action
	switch (type) {
		case 'LOAD_COMMUNICATIONS':
			return {
				...state,
				entities: payload, // Loads all communications into state
			}

		case 'LOAD_COMMUNICATION_BY_ID':
			return {
				...state,
				selectedCommunication: payload, // Loads single communication by ID
			}

		case 'DELETE_COMMUNICATION_SUCCESS':
			// Remove the deleted communication from state
			return {
				...state,
				entities: state.entities.filter((comm) => comm.id !== payload),
			}

		default:
			// Handles the remaining CRUD actions
			state = crudHandlers(state, action, 'communications')
			return state
	}
}
