import { applyMiddleware, combineReducers, compose, createStore } from 'redux';

// Reducers
import accountingAccountReducer from './reducers/accounting_accounts';
import accountingAccountConfigurationReducer from './reducers/accounting_account_configurations';
import additiveSupplyReducer from './reducers/additive_supply';
import appReducer from './reducers/app';
import articleReducer from './reducers/articles';
import articleCostCenterReducer from './reducers/articles_cost_center';
import assetReducer from './reducers/assets';
import bankReducer from './reducers/banks';
import bankAccountReducer from './reducers/bank_accounts';
import bank_catalogReducer from './reducers/bank_catalog';
import bank_key_electronic_paymentReducer from './reducers/bank_key_electronic_payment';
import bankTransactionReducer from './reducers/bank_transactions';
import bankTypeReducer from './reducers/bank_type';
import boothReducer from './reducers/booths';
import branch_officeReducer from './reducers/branch_office';
import budgetReducer from './reducers/budget';
import budget_periodReducer from './reducers/budget_period';
import categoryReducer from './reducers/categorys';
import category_formReducer from './reducers/category_forms';
import category_tourReducer from './reducers/category_tours';
import checkpointsReducer from './reducers/tour_chekpoints';
import cityReducer from './reducers/citys';
import clientReducer from './reducers/clients';
import clientBranchReducer from './reducers/client_branches';
import clientGroupReducer from './reducers/client_groups';
import clientRouteReducer from './reducers/client_routes';
import client_visitReducer from './reducers/client_visits';
import client_visit_trafficReducer from './reducers/client_visit_traffics';
import clientWorkShiftReducer from './reducers/client_work_shifts';
import communicationReducer from './reducers/communication';
import communication_userReducer from './reducers/communication_user';
import companyReducer from './reducers/companys';
import consignment_letterReducer from './reducers/consignment_letters';
import contactProspectReducer from './reducers/contacts_prospects';
import costCenterReducer from './reducers/cost_centers';
import customReducer from './reducers/custom';
import customCatalogReducer from './reducers/custom_catalog';
import customCatalogInstanceReducer from './reducers/custom_catalog_instance';
import dashboardReducer from './reducers/dashboard';
import deliveryReducer from './reducers/deliverys';
import deliveryRouteReducer from './reducers/delivery_routes';
import departmentReducer from './reducers/departments';
import distribution_centerReducer from './reducers/distribution_centers';
import documentReducer from './reducers/document';
import document_categorys from './reducers/document_categorys';
import documentDashboardConfigurationReducer from './reducers/document_dashboard_configurations';
import document_groupReducer from './reducers/documents_groups';
import documentItemsReducer from './reducers/document_items';
import documentProspectReducer from './reducers/documents_prospects';
import driverReducer from './reducers/drivers';
import eldReducer from './reducers/eld';
import electronicPaymentReducer from './reducers/electronic_payment';
import employeeReducer from './reducers/employees';
import employeesReducer from './reducers/guard_house_employees';
import employeeUnitReducer from './reducers/employee_units';
import employeeWorkShiftReducer from './reducers/employee_work_shifts';
import encuestaReducer from './reducers/encuesta';
import eventReducer from './reducers/events';
import exchangeRateReducer from './reducers/exchange_rate';
import expenseReducer from './reducers/expenses';
import expense_typeReducer from './reducers/expenses_types';
import fastCardReducer from './reducers/fast_cards';
import formReducer from './reducers/forms';
import formInstanceReducer from './reducers/form_instances';
import fuelSupplyReducer from './reducers/fuel_supplies';
import fuel_voucherReducer from './reducers/fuel_vouchers';
import general_parameterReducer from './reducers/general_parameters';
import groupPassengerReducer from './reducers/group_passengers';
import guardHouseParametersReducer from './reducers/guard_house_parameters';
import hr_categoryReducer from './reducers/hr_category';
import impression_formatReducer from './reducers/impression_format';
import impression_format_relReducer from './reducers/impression_format_rel';
import incidentReducer from './reducers/incidents';
import incidentCategoryReducer from './reducers/incident_category';
import incident_logsReducer from './reducers/incident_log';
import incidentSubcategoryReducer from './reducers/incident_subcategory';
import inspectionCategoryReducer from './reducers/inspection_category';
import inspection_conceptReducer from './reducers/inspection_concepts';
import inspection_formatReducer from './reducers/inspection_formats';
import inspection_ResultsReducer from './reducers/inpsection_results';
import inspection_sectionReducer from './reducers/inspection_sections';
import inspectionSubcategoryReducer from './reducers/inspection_subcategory';
import internal_orderReducer from './reducers/internal_order';
import invoiceReducer from './reducers/invoices';
import invoice_supplier_codeReducer from './reducers/invoice_supplier_code';
import jobTitleReducer from './reducers/job_title';
import labelReducer from './reducers/labels';
import locationReducer from './reducers/locations';
import merchandiseTypeReducer from './reducers/merchandise_types';
import mobile_routeReducer from './reducers/mobile_routes';
import mobile_route_reportReducer from './reducers/mobile_route_reports';
import module_group_relReducer from './reducers/module_group_rel';
import module_wizard_relReducer from './reducers/module_wizard_rel';
import notificationConfigurationReducer from './reducers/notification_configurations';
import notificationEmailReducer from './reducers/notification_email';
import origin_destinationReducer from './reducers/origins_destinations';
import passengerReducer from './reducers/passengers';
import payment_authorizationReducer from './reducers/payment_authorization';
import payment_conditionsReducer from './reducers/payment_conditions';
import pending_fileReducer from './reducers/pending_file';
import performanceConfigurationReducer from './reducers/performance_configuration';
import personalTransportationReducer from './reducers/personal_transportations';
import personalTransportationExtraReducer from './reducers/personal_transportation_extras';
import personalTransportationParametersReducer from './reducers/personal_transportation_parameters';
import placeReducer from './reducers/places';
import print_parameterReducer from './reducers/print_parameters';
import procedureReducer from './reducers/procedure';
import productReducer from './reducers/products';
import prospectReducer from './reducers/prospects';
import prospect_categoryReducer from './reducers/prospect_category';
import prospect_historialReducer from './reducers/prospect_historial';
import ptUnitAssignationReducer from './reducers/pt_unit_assignations';
import purchase_ordersReducer from './reducers/purchase_orders';
import requisitionReducer from './reducers/requisitions';
import roleReducer from './reducers/roles';
import routeReducer from './reducers/routes';
import routeSetupReducer from './reducers/route_setups';
import section_for_customReducer from './reducers/section_for_custom';
import securitySealReducer from './reducers/security_seals';
import securitySealParametersReducer from './reducers/security_seal_parameters';
import seller_supportReducer from './reducers/seller_supports';
import serviceReducer from './reducers/services';
import serviceCategoryReducer from './reducers/service_categorys';
import serviceOrderReducer from './reducers/service_orders';
import serviceTemplateReducer from './reducers/service_templates';
import shoppingParametersReducer from './reducers/shopping_parameters';
import starmilkRouteReducer from './reducers/starmilk_routes';
import stateReducer from './reducers/states';
import stationReducer from './reducers/stations';
import statusProspectReducer from './reducers/status_prospects';
import stockReducer from './reducers/stocks';
import storageReducer from './reducers/storages';
import storageMovementReducer from './reducers/storage_movements';
import storageMovementDetailReducer from './reducers/storage_movement_details';
import storeParametersReducer from './reducers/store_parameters';
import subcategoryFormReducer from './reducers/subcategory_form';
import subcategoryTourReducer from './reducers/subcategory_tour';
import superAdminReducer from './reducers/super_admin';
import supplierReducer from './reducers/guard_house_supplier';
import suppliersReducer from './reducers/suppliers';
import supplierGroupReducer from './reducers/supplier_groups';
import supplier_invoiceReducer from './reducers/supplier_invoice';
import sysCompanyReducer from './reducers/sys_companys';
import sysModuleReducer from './reducers/sys_modules';
import sys_module_relReducer from './reducers/sys_module_rel';
import tasksReducer from './reducers/tasks';
import tireBrandReducer from './reducers/tire_brands';
import tireEventHistoryReducer from './reducers/tire_event_histories';
import tireStateReducer from './reducers/tires';
import tourReducer from './reducers/tours';
import tour_scanningReducer from './reducers/tour_scanning';
import tourTasksReducer from './reducers/tour_task';
import trafficTicketReducer from './reducers/traffic_tickets';
import trailerReducer from './reducers/trailers';
import trailerAxleTemplateReducer from './reducers/trailer_axle_templates';
import trailerGroupReducer from './reducers/trailer_groups';
import trailerOdometerHistoryReducer from './reducers/trailer_odometer_historys';
import trailerWialonOdometerHistoryReducer from './reducers/trailer_wialon_odometer_historys';
import transport_figureReducer from './reducers/transport_figures';
import transport_meanReducer from './reducers/transport_means';
import typeOfIDReducer from './reducers/type_of_id';
import unitReducer from './reducers/units';
import unitAxleTemplateReducer from './reducers/unit_axle_templates';
import unitGroupReducer from './reducers/unit_groups';
import unit_Hourmeter_History_Reducer from './reducers/unit_hourmeter_history';
import unitMeasureReducer from './reducers/unit_measure';
import unitMeasureEquivalenceReducer from './reducers/unit_measure_equivalence';
import unitOdometerHistoryReducer from './reducers/unit_odometer_historys';
import unitWialonOdometerHistoryReducer from './reducers/unit_wialon_odometer_historys';
import userReducer from './reducers/users';
import userDownloadPermissionsReducer from './reducers/user_download_permissions';
import user_document_groupReducer from './reducers/users_documents_groups';
import visitsReducer from './reducers/guard_house_visits';
import weatheringConfigurationReducer from './reducers/weathering_configuration';
import workShiftReducer from './reducers/work_shifts';
import workshop_parameterReducer from './reducers/workshop_parameters';
import guardUnitReducer from './reducers/guard_house_unit';
import delivery_route_zoneReducer from './reducers/delivery_route_zones';
import ceiba_accountReducer from './reducers/ceiba_account';
import ticketReducer from './reducers/tickets';

// Middleware
import thunk from 'redux-thunk';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
  const store = createStore(
    combineReducers({
      app: appReducer,
      products: productReducer,
      suppliers: suppliersReducer,
      companys: companyReducer,
      units: unitReducer,
      drivers: driverReducer,
      locations: locationReducer,
      deliverys: deliveryReducer,
      clients: clientReducer,
      merchandise_types: merchandiseTypeReducer,
      invoices: invoiceReducer,
      routes: routeReducer,
      work_shifts: workShiftReducer,
      job_titles: jobTitleReducer,
      places: placeReducer,
      employees: employeeReducer,
      users: userReducer,
      expenses: expenseReducer,
      starmilk_routes: starmilkRouteReducer,
      route_setups: routeSetupReducer,
      trailers: trailerReducer,
      employee_units: employeeUnitReducer,
      personal_transportations: personalTransportationReducer,
      client_routes: clientRouteReducer,
      client_work_shifts: clientWorkShiftReducer,
      pt_unit_assignations: ptUnitAssignationReducer,
      roles: roleReducer,
      departments: departmentReducer,
      states: stateReducer,
      client_branchs: clientBranchReducer,
      tires: tireStateReducer,
      procedures: procedureReducer,
      tire_brands: tireBrandReducer,
      unit_axle_templates: unitAxleTemplateReducer,
      trailer_axle_templates: trailerAxleTemplateReducer,
      fuel_supplys: fuelSupplyReducer,
      events: eventReducer,
      stations: stationReducer,
      tire_event_historys: tireEventHistoryReducer,
      weathering_configurations: weatheringConfigurationReducer,
      performance_configurations: performanceConfigurationReducer,
      services: serviceReducer,
      personal_transportation_extras: personalTransportationExtraReducer,
      documents: documentReducer,
      document_items: documentItemsReducer,
      traffic_tickets: trafficTicketReducer,
      fast_cards: fastCardReducer,
      sys_companys: sysCompanyReducer,
      sys_modules: sysModuleReducer,
      cost_centers: costCenterReducer,
      unit_measures: unitMeasureReducer,
      unit_measures_equivalences: unitMeasureEquivalenceReducer,
      storages: storageReducer,
      categorys: categoryReducer,
      storage_movements: storageMovementReducer,
      storage_movement_details: storageMovementDetailReducer,
      accounting_accounts: accountingAccountReducer,
      accounting_account_configurations: accountingAccountConfigurationReducer,
      additive_supply: additiveSupplyReducer,
      articles: articleReducer,
      article_cost_centers: articleCostCenterReducer,
      assets: assetReducer,
      banks: bankReducer,
      bank_accounts: bankAccountReducer,
      bank_catalogs: bank_catalogReducer,
      bank_key_electronic_payments: bank_key_electronic_paymentReducer,
      bank_transactions: bankTransactionReducer,
      bank_types: bankTypeReducer,
      booths: boothReducer,
      branch_offices: branch_officeReducer,
      budgets: budgetReducer,
      budget_periods: budget_periodReducer,
      category_forms: category_formReducer,
      category_tours: category_tourReducer,
      citys: cityReducer,
      client_groups: clientGroupReducer,
      client_visits: client_visitReducer,
      client_visit_traffics: client_visit_trafficReducer,
      communicationss: communicationReducer,
      communication_users: communication_userReducer,
      consignment_letters: consignment_letterReducer,
      contact_prospects: contactProspectReducer,
      customs: customReducer,
      custom_catalogs: customCatalogReducer,
      custom_catalog_instances: customCatalogInstanceReducer,
      dashboard: dashboardReducer,
      delivery_routes: deliveryRouteReducer,
      departments: departmentReducer,
      distribution_centers: distribution_centerReducer,
      document_categorys: document_categorys,
      document_dashboard_configurations: documentDashboardConfigurationReducer,
      document_groups: document_groupReducer,
      document_prospects: documentProspectReducer,
      eld: eldReducer,
      electronic_payments: electronicPaymentReducer,
      employee_work_shifts: employeeWorkShiftReducer,
      exchange_rates: exchangeRateReducer,
      expense_types: expense_typeReducer,
      forms: formReducer,
      form_instances: formInstanceReducer,
      fuel_vouchers: fuel_voucherReducer,
      general_parameters: general_parameterReducer,
      group_passengers: groupPassengerReducer,
      guard_house_employeess: employeesReducer,
      guard_house_parameterss: guardHouseParametersReducer,
      guard_house_suppliers: supplierReducer,
      guard_house_visitss: visitsReducer,
      hr_categorys: hr_categoryReducer,
      impression_formats: impression_formatReducer,
      impression_format_rels: impression_format_relReducer,
      incidents: incidentReducer,
      incident_categorys: incidentCategoryReducer,
      incident_logs: incident_logsReducer,
      incident_subcategorys: incidentSubcategoryReducer,
      inspection_categorys: inspectionCategoryReducer,
      inspection_concepts: inspection_conceptReducer,
      inspection_formats: inspection_formatReducer,
      inspection_results: inspection_ResultsReducer,
      inspection_sections: inspection_sectionReducer,
      inspection_subcategorys: inspectionSubcategoryReducer,
      internal_orders: internal_orderReducer,
      invoice_supplier_codes: invoice_supplier_codeReducer,
      labels: labelReducer,
      mobile_routes: mobile_routeReducer,
      mobile_route_reports: mobile_route_reportReducer,
      module_group_rels: module_group_relReducer,
      module_wizard_rels: module_wizard_relReducer,
      notification_configurations: notificationConfigurationReducer,
      notification_emails: notificationEmailReducer,
      origin_destinations: origin_destinationReducer,
      passengers: passengerReducer,
      payment_authorizations: payment_authorizationReducer,
      payment_conditions: payment_conditionsReducer,
      pending_files: pending_fileReducer,
      places: placeReducer,
      print_parameters: print_parameterReducer,
      prospects: prospectReducer,
      prospect_categorys: prospect_categoryReducer,
      prospect_hs: prospect_historialReducer,
      purchase_orders: purchase_ordersReducer,
      questions: encuestaReducer,
      requisitions: requisitionReducer,
      section_for_customs: section_for_customReducer,
      security_seals: securitySealReducer,
      security_seal_parameterss: securitySealParametersReducer,
      seller_supports: seller_supportReducer,
      service_categorys: serviceCategoryReducer,
      service_orders: serviceOrderReducer,
      service_templates: serviceTemplateReducer,
      shopping_parameterss: shoppingParametersReducer,
      status_prospects: statusProspectReducer,
      stocks: stockReducer,
      store_parameterss: storeParametersReducer,
      subcategory_forms: subcategoryFormReducer,
      subcategory_tours: subcategoryTourReducer,
      super_admins: superAdminReducer,
      supplier_groups: supplierGroupReducer,
      supplier_invoices: supplier_invoiceReducer,
      sys_module_rels: sys_module_relReducer,
      taskss: tasksReducer,
      tours: tourReducer,
      tour_checkpoints: checkpointsReducer,
      tour_checkpoint_logss: tour_scanningReducer,
      tour_task: tourTasksReducer,
      traffic_tickets: trafficTicketReducer,
      trailer_groups: trailerGroupReducer,
      trailer_odometer_historys: trailerOdometerHistoryReducer,
      trailer_wialon_odometer_historys: trailerWialonOdometerHistoryReducer,
      transport_figures: transport_figureReducer,
      transport_means: transport_meanReducer,
      type_of_ids: typeOfIDReducer,
      unit_groups: unitGroupReducer,
      unit_hourmeter_historys: unit_Hourmeter_History_Reducer,
      unit_odometer_historys: unitOdometerHistoryReducer,
      user_document_groups: user_document_groupReducer,
      user_download_permissions: userDownloadPermissionsReducer,
      weathering_configurations: weatheringConfigurationReducer,
      workshop_parameters: workshop_parameterReducer,
      guard_house_units: guardUnitReducer,
      delivery_route_zones: delivery_route_zoneReducer,
      ceiba_accounts: ceiba_accountReducer,
      tickets: ticketReducer,
      personal_transportation_parameterss: personalTransportationParametersReducer,
    }),
    composeEnhancers(applyMiddleware(thunk))
  );
  return store;
};
