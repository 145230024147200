import crudHandlers from "./_crud";

export default (state, action) => {
    const { type, payload } = action;
    switch (type) {

        case 'LOAD_REPORT':
            return { ...state, report: payload }

        default:

            state = crudHandlers(state, action, 'mobile_route_report')

            return state
    }
}