import crudHandlers from './_crud'

export default (state, action) => {
	const { type } = action
	switch (type) {
		case 'LOAD_INSPECTION_CATEGORIES': {
			return {
				...state,
				entities: action.payload,
			}
		}

		default:
			state = crudHandlers(state, action, 'inspection_category')

			return state
	}
}
