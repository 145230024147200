import crudHandlers from "./_crud";

export default (state, action) => {
	const { type } = action;
	switch (type) {
		case 'LOAD_DATA_FROM_PURCHASE_ORDER': {
			var { response, payload } = action
			return {
				...state,
				is_fetching: false,
				purchase_order: response,
				current_purchase_order: payload,
			}
		}
		case 'UPDATE_DATA_PURCHASE_ORDER': {
			var { response, payload } = action
			var { item, createdItems, deletedItems } = response

			return {
				...state,
				is_fetching: false,
				articles_sync: { createdItems, deletedItems },
				consignment_letter: item,
			}
		}
    
		default:
			state = crudHandlers(state, action, 'purchase_order')

			return state
	}
}