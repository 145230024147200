import crudHandlers from "./_crud";

export default (state, action) => {
  const { type } = action;

  switch (type) {
    case "LOAD_PRODUCTS_FROM_INVOICE": {
      var { response, payload } = action;
      return {
        ...state,
        is_fetching: false,
        products_from_invoice: response,
        current_invoice: payload
      }
    }
    case "SYNC_INVOICE_PRODUCTS": {
      var { response, payload } = action;
      var {item, deletedItems, createdItems, updateItems} = response;

      return {
        ...state,
        is_fetching: false,
        products_sync: {deletedItems, createdItems, updateItems},
        invoice: item
      }
    }
    default:

      state = crudHandlers(state, action, 'invoice')

      return state
  }
}