import crudHandlers from './_crud'
export default (state, action) => {
	
	const { type } = action
	switch (type) {
		case 'GET_TRAILER_TYPES': {
			var { response } = action

			return {
				...state,
				is_fetching: false,
				types: response,
			}
		}

		case 'GET_ALL_TRAILERS_BY_USERTYPE': {
			return {
				...state,
				entities: action.payload,
			}
		}
		default:
			state = crudHandlers(state, action, 'trailer')

			return state
	}
}
