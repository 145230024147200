import crudHandlers from "./_crud";

export default (state, action) => {
    const { type } = action;
    switch (type) {
        case 'LOAD_SUBCATEGORY_FORM':
            return {
                ...state,
                entities: action.payload
            }
        default:

            state = crudHandlers(state, action, 'subcategory_form')

            return state
    }
}