import crudHandlers from './_crud'

export default (state = {}, action) => {
	const { type, response } = action

	switch (type) {
		case 'LOAD_SELECTED_SYS_COMPANIES_AND_FORMAT_IMPRESSION':
			return {
				...state,
				selectedSysCompaniesWithFormatImpression: response, // Store the API response in the state
			}

		default:
			// Handle CRUD operations for 'impression_format_rel' by delegating to `crudHandlers`
			state = crudHandlers(state, action, 'impression_format_rel')
			return state
	}
}
