const initialState = {}
export default (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case 'LOGIN': {
			return {
				...state,
				user: { ...payload },
			}
		}
		case 'LOGOUT': {
			return {
				...state,
				user: null,
				superAdmin: null,
			}
		}
		case 'LOADING_APP': {
			return {
				...state,
				loading: payload,
			}
		}
		case 'LOADING_APP': {
			return {
				...state,
				loading: payload,
			}
		}
		case 'ERROR_LOGIN_WIALON': {
			return {
				...state,
				login_wialon: { error: true },
				loading: false,
			}
		}
		case 'LOGIN_WIALON': {
			return {
				...state,
				login_wialon: { ...payload },
				loading: false,
			}
		}
		case 'CHANGE_USER_WIALON': {
			return {
				...state,
				change_user_authorized: payload,
				loading: false,
			}
		}

		case 'LOGIN_CLIENT': {
			return {
				...state,
				client: { ...payload.client },
			}
		}

		case 'CLIENT_LOGOUT': {
			return {
				...state,
				client: null,
			}
		}

		case 'LOGIN_PASSENGER': {
			return {
				...state,
				passenger: { ...payload.passenger },
			}
		}

		case 'PASSENGER_LOGOUT': {
			return {
				...state,
				passenger: null,
			}
		}

		case 'LOGIN_DRIVER': {
			return {
				...state,
				driver: { ...payload.driver },
			}
		}
		case 'DRIVER_LOGOUT': {
			return {
				...state,
				driver: null,
			}
		}
		case 'LOGIN_SUPPLIER': {
			return {
				...state,
				supplier: { ...payload.supplier },
			}
		}
		case 'SUPPLIER_LOGOUT': {
			return {
				...state,
				supplier: null,
			}
		}
		case 'IMPORT': {
			return {
				...state,
			}
		}
		case 'SET_INITIAL_DATE': {
			return {
				...state,
				inspection_info_reducer: payload,
			}
		}
		case 'SET_LANGUAGE': {
			return {
				...state, 
				lang: payload
			}
		}
		case 'LOGIN_SUPER_ADMIN': {
			return {
				...state,
				superAdmin: payload,
			}
		}
		case 'GET_TIME_ZONES_LIST': {
			return {
				...state,
				superAdmin: {
					...state.superAdmin,
					time_zones: payload,
				},
			}
		}
		case 'UPDATE_SYS_COMPANY_LIST': {
			return {
				...state,
				superAdmin: {
					...state.superAdmin,
					sysCompanies: payload,
				},
			}
		}

		case 'INIT_LAST_VISITS_LIST': {
			return {
				...state,
				user: {
					...state.user,
					last_visits: []
				}
			}
		}

		case 'SET_LAST_VISIT_LIST': {
			return {
				...state,
				user: {
					...state.user,
					last_visits: payload
				}
			}
		}
		default:
			return state
	}
}
