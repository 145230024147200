import crudHandlers from "./_crud";

export default (shopping_parameters, action) => {
    const { type } = action;
    switch (type) {

        default:

        shopping_parameters = crudHandlers(shopping_parameters, action, 'shopping_parameters')

            return shopping_parameters
    }
}