const initialState = {
    filter: 0,
}

export default function dashboard(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case 'SET_FILTER': {
            return {
                ...state,
                filter: payload,
            }
        }

        default:
            return state
    }
}