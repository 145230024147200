import crudHandlers from './_crud'

export default (state, action) => {
	const { type } = action
	switch (type) {
		case 'GET_ALL_FORMS':
			return {
				...state,
				entities: action.payload.response,
			}

		case 'GET_FORM':
				return {
					...state,
					entities: action.payload,
				}
		default:
			state = crudHandlers(state, action, 'form')

			return state
	}
}
