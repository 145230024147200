import crudHandlers from "./_crud";

export default (guard_house_parameters, action) => {
    const { type } = action;
    switch (type) {

        default:

        guard_house_parameters = crudHandlers(guard_house_parameters, action, 'guard_house_parameters')

            return guard_house_parameters
    }
}