import crudHandlers from './_crud'

export default (state = {}, action) => {
	const { type, response } = action
	

	switch (type) {
		case 'LOAD_FORMAT_IMPRESSIONS':
			return {
				...state,
				entities: response, // Assuming `response` contains the list of format impressions
			}

		case 'LOAD_FORMAT_IMPRESSION_BY_ID':
			return {
				...state,
				selectedImpression: response, // Assuming `response` contains the format impression for editing
			}

		default:
			// Handle CRUD operations for 'impression_format' by delegating to `crudHandlers`
			state = crudHandlers(state, action, 'impression_format')
			return state
	}
}
