import crudHandlers from "./_crud";

export default (security_seal_parameters, action) => {
    const { type } = action;
    switch (type) {

        default:

        security_seal_parameters = crudHandlers(security_seal_parameters, action, 'security_seal_parameters')

            return security_seal_parameters
    }
}