import crudHandlers from './_crud'

export default (state, action) => {
	const { type } = action
	switch (type) {
		// case 'LOAD_CUSTOM_CATALOGS':
		// 	return {
		// 		...state,
		// 		entities: action.response,
		// 	}

		default:
			state = crudHandlers(state, action, 'custom_catalog')

			return state
	}
}
