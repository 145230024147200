import crudHandlers from "./_crud";

export default (state, action) => {
    const { type } = action;
    switch (type) {

        case 'GET_MONITORING_REPORT': {
            var { response } = action
    
            return {
                ...state,
                is_fetching: false,
                monitoring_report: response,
            }
        }

        default:

            state = crudHandlers(state, action, 'delivery_route')

            return state
    }
}