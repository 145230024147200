import crudHandlers from "./_crud";

export default (state, action) => {
  const {type} = action
  switch (type) {
    
    default:

      state = crudHandlers(state, action, 'unit_odometer_history')

      return state
  }
}