import crudHandlers from './_crud'

export default (state, action) => {
	const { type } = action
	switch (type) {
		case 'LOAD_DRIVERS_PERMISSIONS': 
			return { ...state, currentPermissions: action.payload }

		case 'GET_WIALON_DRIVERS': {
			var { response } = action

			return {
				...state,
				is_fetching: false,
				wialon_drivers: response.error ? [] : response,
			}
		}

		case 'LOAD_EMPLOYEES_BY_USERTYPE':{
			return {
				...state,
				entities: action.payload
			}
		}

		default:
			state = crudHandlers(state, action, 'employee')

			return state
	}
}
