import { convertToArrayObject } from "utils";

const initialState = {
  entities: {},
  is_fetching: false,
  active: undefined
}


export default (state = initialState, action, entity, error, parseBody) => {
  let { type, response, payload } = action;

  switch (type) {
    case `LOAD_${entity.toUpperCase()}S`: {

      if (parseBody)
        response = response.map(item => parseBody(item))

      //  It first transform the array into an object.
      let items = convertToArrayObject(response);
      return {
        ...state,
        entities: items,
        is_fetching: false

      };
    }

    case `LOAD_${entity.toUpperCase()}`: {
      if (parseBody)
        response = parseBody(response)
      let org = state.entities[response.id]

      return {
        ...state,
        is_fetching: false,
        entities: {
          ...state.entities,
          [response.id]: {
            ...org,
            ...response
          }
        }
      }
    }

    case `ADD_${entity.toUpperCase()}`: {
      const entity_id = response.id || response._id;

      if (parseBody)
        response = parseBody(response)

      return {
        ...state,
        entities: {
          ...state.entities,
          [entity_id]: {
            ...state[entity_id],
            ...response
          }
        }

      };
    }

    case `DELETE_${entity.toUpperCase()}`: {
      const entity_id = response.id || response._id;

      if (parseBody)
        response = parseBody(response)

      let newItems = {
        ...state.entities
      };

      delete newItems[entity_id];

      return {
        ...state,
        entities: newItems
      }
    }

    case `UPDATE_${entity.toUpperCase()}`: {
      const entity_id = response.id || response._id;
      if (parseBody)
        response = parseBody(response)
      let org = state.entities[entity_id]

      return {
        ...state,
        entities: {
          ...state.entities,
          [entity_id]: {
            ...org,
            ...response
          }
        }
      };
    }

    case `FETCH_${entity.toUpperCase()}S`: {
      return {
        ...state,
        is_fetching: true
      };
    }

    case `UNLOAD_${entity.toUpperCase()}S`: {
      return {
        entities: {},
        is_fetching: false
      };
    }

    case `SET_${entity.toUpperCase()}`: {
      return {
        ...state,
        active: payload
      };
    }

    case 'LOGOUT': {
      return initialState
    }



    default:
      return state

  }
}