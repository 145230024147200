import crudHandlers from './_crud'

export default (state, action) => {
	const { type } = action
	switch (type) {
		default:
			state = crudHandlers(state, action, 'bank_key_electronic_payment')

			return state
	}
}
