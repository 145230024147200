import crudHandlers from './_crud'

export default (state, action) => {
	const { type } = action
	switch (type) {
		case 'GET_DRIVER_UNITS_CONCEPTS_FORMATS': {
			var { response, payload } = action

			return {
				...state,
				inspection_concepts: response,
			}
		}
		default:
			state = crudHandlers(state, action, 'inspection_concept')

			return state
	}
}
