import crudHandlers from './_crud'

export default (state, action) => {
	const { type } = action
	switch (type) {
		case 'LOAD_CUSTOM_INSTANCES':
			return {
				...state,
				entities: action.payload,
			}
			case 'GET_CUSTOM_CATALOG_INSTANCE_BY_QUESTION':
				return {
					...state,
					entities: action.payload,
				}
		default:
			state = crudHandlers(state, action, 'custom_catalog_instance')

			return state
	}
}
