const initialState = {
    elds: []
}

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case 'LOAD_ELDS': 
            return {...state, elds: payload}
        default:
            return state;
    }
}