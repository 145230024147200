const initialState = {
	refreshing: false,
}

export default (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case 'SET_REFRESHING':
			return {
				...state,
				refreshing: payload,
			}
		default:
			return state
	}
}
