import crudHandlers from "./_crud";

export default (state, action) => {
  const { type } = action;

  switch (type) {
    case "LOAD_ARTICLES_FROM_CONSIGNMENT_LETTER": {
      var { response, payload } = action;
      return {
        ...state,
        is_fetching: false,
        articles_from_consignment_letter: response,
        current_consignment_letter: payload
      }
    }
    case "SYNC_CONSIGNMENT_LETTER_ARTICLES": {
      var { response, payload } = action;
      var {item, deletedItems, createdItems, updateItems} = response;

      return {
        ...state,
        is_fetching: false,
        articles_sync: {deletedItems, createdItems, updateItems},
        consignment_letter: item
      }
    }
    default:

      state = crudHandlers(state, action, 'consignment_letter')

      return state
  }
}