import crudHandlers from './_crud'

export default (state, action) => {
	const { type, response } = action
	switch (type) {
		case 'LOAD_MODULE_GROUP_RELS':
			return {
				...state,
				entities: response, // Set the fetched data to state
			}
		default:
			return crudHandlers(state, action, 'module_group_rel')
	}
}
