import { getQuestionValuesByKey } from 'components/CustomERPForm/components/questionElements'
import crudHandlers from './_crud'

export default (state, action) => {
	const { type } = action
	switch (type) {
		case 'GET_FORM_INSTANCE_BY_USERTYPE': {
			return {
				...state,
				entities: action.payload,
			}
		}

		case 'SET_HIDDENS_LIST': {
			return {
				...state,
				hiddensList: action.payload,
			}
		}

		case 'EDIT_HIDDENS_LIST': {
			let currentState = state
			let currentHiddenList = currentState.hiddensList

			currentHiddenList = currentHiddenList.map((item) => {
				let editValues = action.payload
				let { questionId, answerId } = getQuestionValuesByKey(item)
				if (editValues.answerId == answerId) {
					item.answer = editValues.value
					item.new_to_update = true
					// item.visible_only_report_detail_was_answered = Boolean(item.answer != '')
				}
				item.answer_id = answerId
				return item
			})
			return {
				...state,
				hiddensList: currentHiddenList,
			}
		}
		case 'DATA_WAS_SAVE': {
			return {
				...state,
				hiddensList: action.payload.data,
			}
		}

		case 'EDIT_INDIVIDUAL_HIDDEN': {
			let currentState = state
			let currentHiddenList = currentState.hiddensList

			console.log(currentHiddenList)
			console.log(action.payload)

			currentHiddenList = currentHiddenList.map((item) => {
				let editValues = action.payload
				let { questionId, answerId } = getQuestionValuesByKey(item)
				if (editValues.answerId == answerId && editValues.questionId == questionId) {
					item.new_to_update = true
					item.visible_only_report_detail_was_answered = false

					item.prev_answer = item.answer
					item.answer = item.answer_hidden
					// if (item.answer_hidden && item.type == 'route') {
					// 	item.prev_answer = item.answer
					// 	// item.answer = item.answer_hidden
					// }
				}
				return item
			})

			return {
				...state,
				hiddensList: currentHiddenList,
			}
		}

		default:
			state = crudHandlers(state, action, 'form_instance')

			return state
	}
}
