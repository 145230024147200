import { Icon } from 'semantic-ui-react';

const ModuleNotFound = (props) => {

    return <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column',
        height: 'inherit',
        textAlign: 'center'
    }}>
            <h1 style={{
                fontSize: '2em',
            }}>
            Módulo no encontrado
            </h1>
            <Icon
                style={{
                    display: 'flex',
                    alignSelf: 'center',
                    fontSize: '3em'
                }} 
                name="warning sign"
                size='large'
            />
    </div>
}

export default ModuleNotFound;