import { callApi } from 'utils/api'
import crud from "./_crud";

const actions = crud('sys_module_rel', 'el módulorel', 'los módulosrel');

const getSysModuleRel = (id) => {
    return async (dispatch, getState) => {
        const state = getState()

        try {
        
            const res = await callApi(`/sys_module_rel/get_sys_module_rel/${id}`,{
                method: 'GET',
             
            })
            dispatch({
                type: 'LOAD_SYS_MODULE_REL', 
                response: res.body,
            })
            return res.body;
        } catch (error) {}
    }
}


const createSysModuleRel = (items) => {
    return async (dispatch, getState) => {
        const state = getState()
        try {
            const res = await callApi(`/sys_module_rel/add_sys_module_rel`,{
                method: 'POST',
                headers: {
                    // Authorization: state.app.user.token,
                },
                body:JSON.stringify({items}),
                successMessage: 'Se creo la relacion correctamente'
            })
            return res.body

        }
        catch(error){console.log(error)}
        
    }
}
export default {...actions, getSysModuleRel, createSysModuleRel}