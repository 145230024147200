import { callApi } from 'utils/api'

// Functions to get data for modal hiddens questions in form detail page

const getParams = (userType) => ({
	method: 'GET',
	headers: {
		Authorization: userType.token,
	},
	params: {
		userType: 'user',
		sys_company_id: userType.sys_company_id,
		[`user_id`]: userType.user_id.toString(),
	},
})

export const geClientRouteList = async (userType) => {
	const params = getParams(userType)
	const res = await callApi(`/client_route/get_client_routes_by_usertype`, params)

	return res.body
}

export const loadBranchOfficesList = async (userType) => {
	const params = getParams(userType)
	const res = await callApi(`/branch_office/get_branch_offices_by_usertype`, params)

	return res.body
}

export const loadCustomCatalogsInstancesList = async (userType, item) => {
	const params = {
		method: 'GET',
		headers: {
			Authorization: userType.token,
		},
		params: {
			item: JSON.stringify({
				form_id: item,
			}),
			sys_company_id: userType.sys_company_id,
		},
	}
	// let params = getParams(userType)
	// params.params.item = JSON.stringify({ form_id: item })
	const res = await callApi(
		`/custom_catalog_instance/utils/get_custom_catalog_instance_by_question_usertype`,
		params
	)

	return res.body
}

export const loadEmployeesList = async (userType) => {
	const params = getParams(userType)
	const res = await callApi(`/employee/get_employees_by_usertype`, params)

	return res.body
}

export const loadUnitsList = async (userType) => {
	const res = await callApi(`/unit/form_instance_all_units`, {
		method: 'GET',
		headers: {
			Authorization: userType.token,
		},
		params: { userType: userType, sys_company_id: userType.sys_company_id },
	})

	return res.body.response
}

export const loadClientsList = async (userType) => {
	const params = getParams(userType)
	const res = await callApi(`/client/get_clients_by_usertype`, params)

	return res.body
}

export const loadSuppliersList = async (userType) => {
	const params = getParams(userType)
	const res = await callApi(`/supplier/get_suppliers_by_usertype`, params)

	return res.body
}

export const loadTrailersList = async (userType) => {
	const params = getParams(userType)
	const res = await callApi(`/trailer/get_trailers_by_usertype`, params)

	return res.body
}

export const loadCompaniesList = async (userType) => {
	const params = getParams(userType)
	const res = await callApi(`/company/get_companies_by_usertype`, params)

	return res.body
}

export const loadArticlesList = async (userType) => {
	const params = getParams(userType)

	const res = await callApi(`/article/utils/get_articles_by_usertype`, params)

	return res.body
}
