import crudHandlers from './_crud'

export default (state, action) => {
	const { type } = action
	switch (type) {
		case 'ADD_SYNC_CLIENT':
		case 'UPDATE_SYNC_CLIENT': {
			var { response } = action
			return {
				...state,
				entities: {
					...state.entities,
					[response.id]: response,
				},
			}
		}
		case 'GET_ALL_CLIENTS_BY_USERTYPE': {
			return {
				...state,
				entities: action.payload,
			}
		}
		default:
			state = crudHandlers(state, action, 'client')

			return state
	}
}
