import crudHandlers from './_crud'

export default (state, action) => {
	const { type } = action
	switch (type) {
		case 'GET_ALL_COMPANIES_BY_USERTYPE':
			return {
				...state,
				entities: action.payload,
			}

		default:
			state = crudHandlers(state, action, 'company')

			return state
	}
}
