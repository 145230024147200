import crudHandlers from './_crud'

const initialState = {
	entities: {},
	is_fetching: false,
	active: undefined,
	superadminUsers: [],
  }

export default (state=initialState, action) => {
	const { type } = action
	switch (type) {
		case 'GET_USERS_BY_USERTYPE': {
			return {
				...state,
				entities: action.payload,
			}
		}
		case 'GET_USER': {

			return {
				...state,
				superadminUsers: action.payload,
			}
		}
		default:
			state = crudHandlers(state, action, 'user')

			return state
	}
}
