import React, { useState, useEffect } from 'react'

import { Accordion, Popup, Icon, Grid, Menu } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import _ from 'lodash'

import './Sidebar.scss'
import styles from './../../styles/global.module.scss'

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window
	return {
		width,
		height,
	}
}

const SupplierSidebarMenu = (props) => {
	const { expand, setExpand, client, location, mobileOnly, setMobileOnly } =
		props

	const [windowDimensions, setWindowDimensions] = useState(
		getWindowDimensions()
	)
	const [isActive, setIsActive] = useState(false)
	const [isSubActive, setIsSubActive] = useState(false)

	useEffect(() => {
		function handleResize() {
			if (windowDimensions !== getWindowDimensions())
				setWindowDimensions(getWindowDimensions())
		}

		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	useEffect(() => {
		if (windowDimensions.width <= 425) {
			setMobileOnly(true)
			if (expand === 1 || expand == null) setExpand(1)
		} else {
			setMobileOnly(false)
		}
	}, [windowDimensions])

	useEffect(() => {
		if (location) {
			!mobileOnly ? setExpand(0) : setExpand(1)
		}
	}, [location])

	return (
		<>
			<div
				className='sidebar'
				style={expand == null || expand == 1 ? { width: '250px' } : null}
			>
				<Accordion
					key={`accordion-menu-sidebar`}
					fluid
					style={{ paddingLeft: '12px', paddingBottom: '5px' }}
				>
					<Accordion.Title
						key={`title-sidebar`}
						active={isActive}
						onClick={(e) => {
							e.preventDefault()
							e.stopPropagation()
							setIsActive(!isActive)
						}}
					>
						<Popup
							trigger={
								<Menu.Item
									className={`${styles.shrinkOnHover} SubMenu__Item`}
									style={{ width: '100%', display: 'inline-block' }}
								>
									<Icon
										name='options'
										size='large'
										className='SideBar__Outline'
									/>
									{(expand == null || expand == 1) && (
										<h5 className='menu-row-title'> Opciones </h5>
									)}
								</Menu.Item>
							}
							positionFixed
							position='right center'
							pinned
							hoverable
							closeOnTriggerClick={true}
							offset={[0, 0]}
							className='SubMenu__Content'
							content={
								<Grid divided='vertically' style={{ width: '15rem' }}>
									<Grid.Row style={{ paddingBottom: 0 }}>
										<Grid.Column>
											<h4>Opciones</h4>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row style={{ paddingTop: '0', paddingBottom: '0' }}>
										<Grid.Column>
											<Accordion.Content
												key={`content-entidades`}
												style={{ marginTop: '0px', paddingTop: '0px' }}
												active={isActive}
											>
												<Menu
													vertical
													fluid
													text
													style={{ paddingLeft: '30px' }}
												>
													<Link
														key={`link-dashboard`}
														to={`/supplier/supplier_dashboard`}
														className='sub_menu_row'
													>
														<Menu.Item
															className={styles.growOnHover}
															style={{ margin: '0' }}
														>
															Principal
														</Menu.Item>
													</Link>
												</Menu>
											</Accordion.Content>
										</Grid.Column>
									</Grid.Row>
								</Grid>
							}
						/>

						<Popup
							trigger={
								<Menu.Item
									className={`${styles.shrinkOnHover} SubMenu__Item`}
									style={{
										width: '100%',
										display: 'inline-block',
										marginTop: '10px',
									}}
								>
									<Icon
										name='wordpress forms'
										size='large'
										className='SideBar__Outline'
									/>
									{(expand == null || expand == 1) && (
										<h5 className='menu-row-title'> Opciones </h5>
									)}
								</Menu.Item>
							}
							positionFixed
							position='right center'
							pinned
							hoverable
							closeOnTriggerClick={true}
							offset={[0, 0]}
							className='SubMenu__Content'
							content={
								<Grid divided='vertically' style={{ width: '15rem' }}>
									<Grid.Row style={{ paddingBottom: 0 }}>
										<Grid.Column>
											<h4>Formularios</h4>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row style={{ paddingTop: '0', paddingBottom: '0' }}>
										<Grid.Column>
											<Accordion.Content
												key={`content-transporte de personal`}
												style={{ marginTop: '0px', paddingTop: '0px' }}
												active={isActive}
											>
												<Menu
													vertical
													fluid
													text
													style={{ paddingLeft: '30px' }}
												>
													<Link
														key={`link-mobile_routes_report`}
														to={'/supplier/form_instance'}
														className='sub_menu_row'
													>
														<Menu.Item
															className={styles.growOnHover}
															style={{ margin: '0' }}
														>
															Responder Formulario
														</Menu.Item>
													</Link>

													<Link
														key={`link-mobile_routes_report`}
														to={`/supplier/form_instance_report_detail`}
														className='sub_menu_row'
													>
														<Menu.Item
															className={styles.growOnHover}
															style={{ margin: '0' }}
														>
															Reporte detallado de procesos
														</Menu.Item>
													</Link>
												</Menu>
											</Accordion.Content>
										</Grid.Column>
									</Grid.Row>
								</Grid>
							}
						/>

						<Popup
							trigger={
								<Menu.Item
									className={`${styles.shrinkOnHover} SubMenu__Item`}
									style={{ width: '100%', display: 'inline-block' }}
								>
									<Icon name='folder open' size='large' className='SideBar__Outline' />
									{(expand == null || expand == 2) && (
										<h5 className='menu-row-title'>Documentos</h5>
									)}
								</Menu.Item>
							}
							positionFixed
							position='right center'
							pinned
							hoverable
							closeOnTriggerClick={true}
							offset={[0, 0]}
							className='SubMenu__Content'
							content={
								<Grid divided='vertically' style={{ width: '15rem' }}>
									<Grid.Row style={{ paddingBottom: 0 }}>
										<Grid.Column>
											<h4>Documentos</h4>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row style={{ paddingTop: '0', paddingBottom: '0' }}>
										<Grid.Column>
											<Accordion.Content
												key={`content-documentos`}
												style={{ marginTop: '0px', paddingTop: '0px' }}
												active={isActive}
											>
												<Menu vertical fluid text style={{ paddingLeft: '30px' }}>
													<Link
														key={`link-dashboard`}
														to={`/supplier/supplier_documents`}
														className='sub_menu_row'
													>
														<Menu.Item className={styles.growOnHover} style={{ margin: '0' }}>
															Documentos
														</Menu.Item>
													</Link>
												</Menu>
											</Accordion.Content>
										</Grid.Column>
									</Grid.Row>
								</Grid>
							}
						/>
					</Accordion.Title>
				</Accordion>
			</div>
		</>
	)
}

export default SupplierSidebarMenu
