import crudHandlers from "./_crud";

export default (state, action) => {
    const { type } = action;
    switch (type) {
      case "UPLOAD_ROUTE_SETUP_EXPENSE_TICKET": {
         var { response, payload } = action;
         return {
           ...state,
           is_fetching: false,
           route_setup: response,
           current_route: payload
         }
       }
        default:

            state = crudHandlers(state, action, 'route_setup')

            return state
    }
}