// procedureReducer.js
import crudHandlers from './_crud'

export default (state, action) => {
	const { type } = action
	switch (type) {
		case 'LOAD_PROCEDURE':
			return {
				...state,
				entities: action.response,
			}
		default:
			return crudHandlers(state, action, 'procedure')
	}
}
