import crudHandlers from "./_crud";

export default (state, action) => {
    const { type } = action;
    switch (type) {

        case 'GET_UNITS_WIALON': {
			var { response } = action

			return {
				...state,
				is_fetching: false,
				wialon: response.error ? []:response,
			}
		}

        default:
            state = crudHandlers(state, action, 'passenger')
            return state
    }
}