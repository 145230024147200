import React from 'react'
import capitalize from 'lodash/capitalize'
import { replaceAll } from 'utils/index'
import es from 'date-fns/locale/es'
import DatePicker from 'react-datepicker'
import { Checkbox, Dropdown, Input, Button, Icon, Header } from 'semantic-ui-react'
import Select from 'react-select'
import Cleave from 'cleave.js/react'
import Dropzone from 'react-dropzone'

import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'

import styles from '../styles/global.module.scss'

const actionButton = `ui button ${styles.Bordered}` + ' ' + `${styles.shadowOnHover}`

export default ({
	field, // { name, value, onChange, onBlur }
	type = 'text',
	withLabel = true,
	options,
	label,
	fileExtensions,
	includeEmptyOption = false,
	readOnly = false,
	checkLabel,
	fileStyle,
	defaultInput = false,
	onDropFiles = null,
	digits = 2,
	form: { touched, errors, setFieldValue, setFieldError, setFieldTouched, submitCount }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
	...props
}) => (
	<div style={props.style}>
		{withLabel === true ? (
			<div className='Modal_form_field_label' htmlFor={field.name}>
				{label ? label : replaceAll(capitalize(field.name), '_', ' ')}
			</div>
		) : (
			<div className='Modal_form_field_label'></div>
		)}

		{(() => {
			let style =
				touched[field.name] && errors[field.name]
					? { ...props.inputStyle, border: 'solid 1px red' }
					: { ...props.inputStyle }
			if (readOnly) {
				style = {
					background: '#eeeeee',
					...style,
				}
			}
			switch (type) {
				case 'date':
					if (defaultInput) {
						return (
							<Input
								className='FieldInput_'
								placeholder='dd/mm/yyyy'
								value={moment(field.value).format('YYYY-MM-DD') || ''}
								onChange={(date) => {
									const { onChange } = props
									if (onChange) onChange(date)
									setFieldTouched(field.name, true)
									setFieldValue(field.name, moment(date.currentTarget.value).toDate())
								}}
								autoComplete='none'
								type={'date'}
							/>
						)
					}
					return (
						<DatePicker
							locale={es}
							selected={field.value}
							{...field}
							{...props}
							onChange={(date) => {
								const { onChange } = props
								if (onChange) onChange(date)
								setFieldTouched(field.name, true)
								setFieldValue(field.name, date)
							}}
						/>
					)
				case 'time':
					return (
						<DatePicker
							showTimeSelect
							showTimeSelectOnly
							timeIntervals={15}
							timeCaption='Hora'
							dateFormat='h:mm aa'
							// locale={es}
							selected={field.value}
							{...field}
							{...props}
							onChange={(date) => {
								const { onChange } = props
								if (onChange) onChange(date)
								setFieldTouched(field.name, true)
								setFieldValue(field.name, date)
							}}
						/>
					)
				case 'select':
					return (
						<select
							className='Modal_form_field_input'
							{...field}
							{...props}
							onChange={(e) => {
								const value = e.target.value
								if (props.onChange) props.onChange(value)

								setFieldValue(field.name, value)
								setFieldTouched(field.name, true)
							}}
							disabled={readOnly}
						>
							{includeEmptyOption && <option key={undefined} value={''}>Seleccione</option>}
							{options &&
								options.map((opt) => (
									<option key={opt.value} value={opt.value}>
										{opt.label}
										{'\u00a0'}
										{/* {opt.description} */}
									</option>
								))}
						</select>
					)
				case 'select-multiple':
					return (
						<Dropdown
							{...field}
							{...props}
							validate={(value) => {
								const { validate } = props
								if (validate) validate(value)
							}}
							fluid
							multiple
							selection
							options={options}
							onChange={(_, data) => {
								const { onChange } = props
								if (onChange) onChange(data.value)
								setFieldValue(field.name, data.value)
							}}
							onBlur={(_, data) => {
								const { onBlur } = props
								if (onBlur) onBlur(data.value)
								setFieldValue(field.name, data.value)
							}}
						/>
					)
				case 'react-select':
					return (
						<Select
							{...field}
							{...props}
							onChange={(data) => {
								const { onChange } = props
								if (onChange) onChange(data.value)
								setFieldValue(field.name, data)
							}}
							options={options}
						/>
					)
				case 'textarea':
					return (
						<div>
							<textarea
								className='Modal_form_field_input'
								style={
									touched[field.name] && errors[field.name] ? { border: 'solid 1px red' } : null
								}
								{...field}
								value={field.value || undefined}
								{...props}
							/>
						</div>
					)
				case 'urltext':
					return (
						<div>
							<b>Documento Adjunto: </b>{field.value || ''}
						</div>
					)
				case 'check':
					return (
						<div>
							<Checkbox
								{...field}
								{...props}
								//label={checkLabel ? checkLabel : label}
								defaultChecked={field.value}
								onChange={(_, data) => {
									const { onChange } = props
									if (onChange) onChange(data.checked)
									setFieldValue(field.name, data.checked)
								}}
							/>
						</div>
					)
				case 'file':
					return (
						<div style={{ display: 'flex', ...fileStyle }}>
							<input
								{...field}
								{...props}
								className='Modal_form_field_input'
								style={
									touched[field.name] && errors[field.name] ? { border: 'solid 1px red' } : null
								}
								value={field.value ? field.value.filename : ''}
								readOnly
							/>

							<input
								type='file'
								style={{ display: 'none' }}
								className='inputfile'
								id='embedpollfileinput'
								accept={fileExtensions ? fileExtensions.join(',') : undefined}
								onChange={(event) => {
									const filename = event.target.files[0].name
									const content = event.target.files[0]
									setFieldValue(field.name, {
										filename,
										content,
									})
								}}
							/>
							<label htmlFor='embedpollfileinput' className='Form_field_label ui icon button'>
								<i className='ui search icon'></i>
							</label>
						</div>
					)
				case 'dropzone': {
					//accepts only one file, it will render the file name when uploaded succesfully
					return (
						<>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
								}}
							>
								{field?.value?.image?.preview && (
									<img
										src={field.value.image.preview}
										style={{ display: 'block', objectFit: 'contain', width: '200px', maxHeight:'200px' }}
									/>
								)}
							

								{field?.value?.image?.preview && (
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											gap: '5px',
											marginTop: '10px',
											marginBottom: '20px',
											color: '#7ec1e6',
										}}
									>
										<label className=''>{field?.value?.name}</label>

										<Icon name='tags' />
									</div>
								)}
							</div>
							<Dropzone
								onDrop={(files) => {
									onDropFiles && onDropFiles(files[0])

									let imagePreview = files.map((e) => {
										return Object.assign(e, {
											preview: URL.createObjectURL(e),
											id: Math.random().toString(30).substring(2),
										})
									})
									setFieldValue(field.name, { name: files[0].name, image: imagePreview[0] })
								}}
								multiple={false}
							>
								{({ getRootProps, getInputProps }) => (
									<div {...getRootProps()} style={{ width: 'max-content' }}>
										<input {...getInputProps()} />
										<Button color='blue' key={`dropzone-file-upload`} type='button' className={actionButton}>
											<Icon name='upload' />
											Adjuntar Imagen
										</Button>
									</div>
								)}
							</Dropzone>
						</>
					)
				}
				case 'currency': {
					return (
						<Cleave
							style={{
								textAlign: 'right',
								...style,
							}}
							className='Modal_form_field_input'
							value={field.value}
							// htmlRef={(input) => {
							//    this.input = input
							// }}
							onChange={(e) => {
								const value = e.target.rawValue
									? e.target.rawValue.length > 0
										? e.target.rawValue
										: 0
									: 0
								let isValid = true
								if (props.maxValue != null) {
									const floatValue = parseFloat(value)
									isValid = floatValue <= props.maxValue
								}

								let newValue
								if (isValid) {
									newValue = value
								} else {
									newValue = props.maxValue
								}

								if (props.onChange) props.onChange(newValue)
								setFieldValue(field.name, newValue)
								setFieldTouched(field.name, true)
							}}
							readOnly={readOnly}
							options={{
								numeral: true,
								rawValueTrimPrefix: true,
								numeralDecimalScale: digits,
								prefix: '$',
							}}
						/>
					)
				}
				case 'numerical': {
					return (
						<div>
							<Cleave
								className='FieldCleave_'
								value={field.value}
								onChange={(e) => {
									if (!isNaN(parseInt(e.target.rawValue))) {
										if (props.onChange) props.onChange(e.target.rawValue)
										setFieldValue(field.name, e.target.rawValue ? e.target.rawValue : null)
										setFieldTouched(field.name, true)
									}
								}}
								options={{
									numeral: true,
									rawValueTrimPrefix: true,
									numeralDecimalScale: digits,
								}}
							/>
						</div>
					)
				}
				default:
					return (
						<div>
							<input
								className='Modal_form_field_input'
								type={type}
								style={{
									...style,
									borderColor: errors[field.name] && submitCount > 0 && 'red',
								}}
								{...field}
								value={field.value || ''}
								readOnly={readOnly}
								{...props}
								onChange={(e) => {
									let value = e.target.value

									if (type === 'number') {
										value = parseInt(value)
									}
									if (props.onChange) props.onChange(value)
									setFieldTouched(field.name, true)
									setFieldValue(field.name, value)
								}}
								onBlur={(e) => {
									let value = e.target.value
									if (props.onBlur) props.onBlur(value)
								}}
							/>
						</div>
					)
			}
		})()}
		{errors[field.name] && submitCount > 0 && (
			<div style={{ color: 'red' }}>{errors[field.name]}</div>
		)}
	</div>
)
