import crudHandlers from "./_crud";

export default (state, action) => {
  const { type } = action;


  switch (type) {

    case "LOAD_LAST_DELIVERY": {
      var { response, payload } = action;
      return {
        ...state,
        is_fetching: false,
        last_by_logged_user: response
      }
    }

    default:

      state = crudHandlers(state, action, 'delivery')

      return state
  }
}