import { showSuccessMessage } from 'utils/messages'
import crud from './_crud'
import { callApi } from 'utils/api'
import Swal from 'sweetalert2'

const entity = 'service_order'

const createInspectionAdditionalComments = (item) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(
			`/inspection_additional_comment/create_inspection_additional_comments`,
			{
				method: 'POST',
				headers: {
					Authorization: state.app.user.token,
				},
				body: JSON.stringify(item),
			}
		)

		if (res.body.message === 'ok') {
			showSuccessMessage('La inspección se guardo correctamente')
		}
		return res.body
	}
}

const createOrder = (item, files) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/service_order/create_order`, {
			method: 'PUT',
			json: false,
			headers: {
				Authorization: state.app.user.token,
			},
			body: {
				item: JSON.stringify(item),
				files: files,
			},
		})

		const newOrderId = res.body.serviceOrderResponse.code

		Swal.fire({
			title: '¡Acción Realizada!',
			text: 'Se creó la orden correctamente. ¿Desea salir o permanecer en la orden de servicio?',
			icon: 'success',
			showCancelButton: true,
			confirmButtonText: 'Permanecer',
			cancelButtonText: 'Salir',
		}).then((result) => {
			if (result.isConfirmed) {
				window.location.href = `${window.location.origin}/workshop/service_order/${newOrderId}`
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				window.location.href = `${window.location.origin}/workshop/service_order`
			}
		})

		return res.body
	}
}

const deleteOrder = (item_id) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/service_order/delete_order/${item_id}`, {
			method: 'DELETE',
			headers: {
				Authorization: state.app.user.token,
			},
			successMessage: 'Orden de servicio eliminada correctamente',
		})
		return res.body
	}
}

const driverCreateInspectionAdditionalComments = (item) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(
			`/inspection_additional_comment/driver_create_inspection_additional_comments`,
			{
				method: 'POST',
				headers: {
					Authorization: state.app.driver.token,
				},
				body: JSON.stringify(item),
			}
		)

		if (res.body.message === 'ok') {
			showSuccessMessage('La inspección se guardo correctamente')
		}
		return res.body
	}
}

const foundInspectionAdditionalComment = (inspection_params) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(
			`/inspection_additional_comment/utils/get_inspection_additional_comment`,
			{
				method: 'GET',
				headers: {
					Authorization: state.app.user.token,
				},
				params: inspection_params,
			}
		)

		dispatch({ type: `LOAD_INSPECTION_ADDITIONAL_COMMENTS`, payload: res.body })
		return res.body
	}
}

const foundServiceOrder = (inspection_params) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(
			`/service_order/utils/get_orders_inspection_concept`,
			{
				method: 'GET',
				headers: {
					Authorization: state.app.user.token,
				},
				params: inspection_params,
			}
		)
		return res.body
	}
}

const foundServiceOrderDriver = (inspection_params) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(
			`/employee/utils/get_orders_inspection_concepts`,
			{
				method: 'GET',
				headers: {
					Authorization: state.app.driver.token,
				},
				params: inspection_params,
			}
		)
		return res.body
	}
}

const getFormByCode = (data) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/form_instance/get_form_by_code`, {
			method: 'GET',
			headers: {
				Authorization: state.app.user.token,
			},
			params: {
				form_code: data.form,
			},
		})

		return res.body
	}
}

const getInspectionByFolio = (data) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/inspection_group_result/utils/get_inspection_by_folio`, {
			method: 'GET',
			headers: {
				Authorization: state.app.user.token,
			},
			params: {
				folio: data.inspection_group_folio,
			},
		})

		return res.body
	}
}

const getFormInstanceById = (data) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/form_instance/utils/get_form_instance_by_id`, {
			method: 'GET',
			headers: {
				Authorization: state.app.user.token,
			},
			params: data,
		})

		return res.body
	}
}

const getNextDateService = (options) => {
	return async (dispatch, getState) => {
		const state = getState()
		const res = await callApi(`/service_template/utils/get_next_date_service`, {
			method: 'GET',
			headers: {
				Authorization: state.app.user.token,
			},
			params: {
				template: JSON.stringify(options.template),
				unit_id: options.unitId,
				trailer_id: options.trailerId,
				category_service: options.categoryService,
				service: options.service,
			},
		})
		return res.body
	}
}

const getServiceOrderByInstanceId = (data) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(
			`/service_order/utils/get_service_order_by_instance_id`,
			{
				method: 'GET',
				headers: {
					Authorization: state.app.user.token,
				},
				params: data,
			}
		)

		// dispatch({ type: ``, payload: res.body })
		return res.body
	}
}

const getServiceOrderByInstanceIdByUsertype = ({ userType }, data) => {
	return async (dispatch, getState) => {
		const state = getState()

		data[`${userType}_id`] = state.app[userType].id
		const res = await callApi(
			`/service_order/get_service_order_by_instance_id_by_usertype`,
			{
				method: 'GET',
				headers: {
					Authorization: state.app[userType].token,
				},
				params: data,
			}
		)

		// dispatch({ type: ``, payload: res.body })
		return res.body
	}
}

const getServiceOrderPDF = ({ ...item }) => {
	return async (dispatch, getState) => {
		const state = getState()

		callApi(`/utils/generate_service_order_bill/${item.currentItem.code}`, {
			method: 'GET',
			json_response: false,
			headers: {
				Authorization: state.app.user.token,
			},
		})
			.then((response) => response.body.blob())
			.then((blob) => {
				const url = window.URL.createObjectURL(
					new Blob([blob], {
						type: 'application/pdf',
					})
				)
				const link = document.createElement('a')
				link.href = url
				link.target = '_blank'
				document.body.appendChild(link)
				link.click()
				link.parentNode.removeChild(link)
			})
			.catch(() => {
				//  showErrorMessage('No se ha adjuntado un logo para esta empresa')
			})
	}
}

const loadAndUpdate = () => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/${entity}/utils/load_and_update`, {
			method: 'PATCH',
			headers: {
				Authorization: state.app.user.token,
			},
		})

		dispatch({
			type: `LOAD_REPORT_${entity.toUpperCase()}`,
			response: res.body,
		})

		return res.body
	}
}

const loadServiceOrderHistory = (id) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(
			`/service_order_history/load_service_order_history/${id}`,
			{
				method: 'GET',
				headers: {
					Authorization: state.app.user.token,
				},
			}
		)

		dispatch({ type: `LOAD_SERVICE_ORDER_HISTORY`, payload: res.body })
		return res.body
	}
}

const loadUnits = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/service_order/utils/load_units`, {
			method: 'GET',
			headers: {
				Authorization: state.app.user.token,
			},
			params: options?.params,
		})

		return res.body
	}
}

const saveServiceOrderForm = (item) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/service_order/save_service_order_form`, {
			method: 'POST',
			headers: {
				Authorization: state.app.user.token,
			},
			body: JSON.stringify(item),
		})
		return res.body
	}
}

const updateAsset = (item, files) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/service_order/modify_order/${item.id}`, {
			method: 'PUT',
			json: false,
			headers: {
				Authorization: state.app.user.token,
			},
			body: {
				item: JSON.stringify(item),
				files: files,
			},

			// files: files,

			// successMessage: `Se edito los artículos correctamente.`,
		})
		const orderId = res.body.response.serviceOrderResponse.code

		Swal.fire({
			title: '¡Acción Realizada!',
			text: 'Se editó la orden correctamente. ¿Desea salir o permanecer en la orden de servicio?',
			icon: 'success',
			showCancelButton: true,
			confirmButtonText: 'Permanecer',
			cancelButtonText: 'Salir',
		}).then((result) => {
			if (result.isConfirmed) {
				window.location.href = `${window.location.origin}/workshop/service_order/${orderId}`
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				window.location.href = `${window.location.origin}/workshop/service_order`
			}
		})
		return res.body
	}
}

const getInspectionResultById = (params) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/service_order/utils/get_inspection_result_by_id`, {
			method: 'GET',
			headers: {
				Authorization: state.app.user.token,
			},
			params: params,
		})

		return res.body
	}
}

const actions = crud(
	'service_order',
	'la orden de servicio',
	'las ordenes de servicio'
)

export default {
	...actions,
	createInspectionAdditionalComments,
	createOrder,
	deleteOrder,
	driverCreateInspectionAdditionalComments,
	foundInspectionAdditionalComment,
	foundServiceOrder,
	foundServiceOrderDriver,
	getFormByCode,
	getFormInstanceById,
	getNextDateService,
	getServiceOrderByInstanceId,
	getServiceOrderByInstanceIdByUsertype,
	getServiceOrderPDF,
	loadAndUpdate,
	loadServiceOrderHistory,
	loadUnits,
	saveServiceOrderForm,
	updateAsset,
	getInspectionResultById,
	getInspectionByFolio
}
