export const getStateValue = (value) => {
   return value ? Object.keys(value).map(key => value[key]) : []
}

export const getPermission = (modules, target_module) => {
   return modules ? modules.find(m => m.url === target_module)?.permisitions: {}
}

export const isAuthorized = (currentRole, roles) => {
   return roles.includes(currentRole)
}
