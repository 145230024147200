import crudHandlers from './_crud'

export default (state, action) => {
	const { type } = action
	switch (type) {
      case 'GET_EVENT_TYPES': {
			var { response } = action

			return {
				...state,
				is_fetching: false,
				types: response,
			}
		}
		case 'GET_TIRE_TYPES': {
			var { response } = action

			return {
				...state,
				is_fetching: false,
				types: response,
			}
		}
		default:
			state = crudHandlers(state, action, 'tire')

			return state
	}
}
