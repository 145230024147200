import crudHandlers from "./_crud";

export default (personal_tranportation_parameters, action) => {
    const { type } = action;
    switch (type) {

        default:

            personal_tranportation_parameters = crudHandlers(personal_tranportation_parameters, action, 'personal_transportation_parameters')

            return personal_tranportation_parameters
    }
}