import crud from './_crud'
import { callApi } from 'utils/api'

const getModuleGroupRel = (id) => {
	return async (dispatch, getState) => {
		const state = getState()

		try {
			const res = await callApi(`/module_group_rel/get/module_group_rel`, {
				method: 'GET',
			})
			dispatch({
				type: 'LOAD_MODULE_GROUP_RELS',
				response: res.body,
			})
			return res.body
		} catch (error) {}
	}
}

const getModuleGroup = (id, options) => {
	return async (dispatch, getState) => {
		const state = getState()
		console.log('calling route')
		try {
			const res = await callApi(
				`/module_group_rel/get/module_group_rel/${id}`,
				{
					method: 'GET',
					// params: options.params,
				}
			)
			dispatch({
				type: 'LOAD_MODULE_GROUP_REL',
				response: res.body,
			})
			return res.body
		} catch (error) {}
	}
}

const updateCheckedStatus = (id, isVisible) => {
	return async (dispatch) => {
		try {
			const res = await callApi(`/module_group_rel/update_checked_status`, {
				method: 'POST',
				body: JSON.stringify({ id, isVisible }),
				headers: {},
				successMessage: 'Se creo la relacion correctamente',
			})

			return res.body
		} catch (error) {
			console.error('Error updating checked status:', error)
		}
	}
}

const actions = crud('module_group_rel', 'el modulo', 'los modulos')

export default {
	...actions,
	getModuleGroupRel,
	getModuleGroup,
	updateCheckedStatus,
}
