import crudHandlers from './_crud'

export default (state, action) => {
	const { type, payload } = action
	switch (type) {
		case 'GET_CEIBA_VEHICLES_GROUP':
			return {
				...state,
				is_fetching: false,
				ceiba_vehicles_groups: payload,
			}

		case 'GET_PASSENGER_COUNTING_REPORT':
			return {
				...state,
				is_fetching: false,
				passenger_counting_report: payload,
			}

		case 'LOGIN_CEIBA':
			return {
				...state,
				is_fetching: false,
				ceiba_account_linked: true,
			}
		
			case 'CHECK_CEIBA_ACCOUNT':
			return {
				...state,
				is_fetching: false,
				ceiba_account_linked: payload,
			}

		default:
			state = crudHandlers(state, action, 'ceiba_account')
			return state
	}
}
