import { callApi } from 'utils/api'
import crud from './_crud'
import Swal from 'sweetalert2'

export const LOGO_TOKEN_VALIDATED_SUCCESS = 'LOGO_TOKEN_VALIDATED_SUCCESS'
export const LOGO_TOKEN_VALIDATED_ERROR = 'LOGO_TOKEN_VALIDATED_ERROR'

export const logoTokenValidatedSuccess = (logoUrl) => ({
	type: LOGO_TOKEN_VALIDATED_SUCCESS,
	payload: { logoUrl },
})

export const logoTokenValidatedError = (error) => ({
	type: LOGO_TOKEN_VALIDATED_ERROR,
	payload: { error },
})

export const validateLogoToken = (logoToken) => {
	return async (dispatch) => {
		try {
			// Llamar al API para validar el logo_token
			console.log('logoToken:', logoToken)
			const response = await callApi(`/app/validateLogoToken?e=${logoToken}`, {
				method: 'POST',
				body: JSON.stringify({ logoToken }),
			})
			const data = await response
			console.log('API response:', response)
			if (response) {
				if (data.body.logoUrl) {
					// Puedes despachar una acción de éxito y devolver el URL del logo
					dispatch(logoTokenValidatedSuccess(data.body.logoUrl))
					return data.body.logoUrl
				} else {
					console.error('Error: Logo URL not provided in the response')
					// Puedes despachar una acción de error si es necesario
					dispatch(
						logoTokenValidatedError('Logo URL not provided in the response')
					)
				}
			} else {
				console.error('Error validating logo token:', data.error)
				// Puedes despachar una acción de error si es necesario
				dispatch(logoTokenValidatedError(data.error))
			}
		} catch (error) {
			console.error('Error validating logo token:', error.message)
			// Puedes despachar una acción de error si es necesario
			dispatch(logoTokenValidatedError(error.message))
		}
	}
}

const upsertSysCompanyBusinessDays = (item) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/sys_company/upsert_sys_company_business_days`, {
			method: 'POST',
			headers: {
				Authorization: state.app.user.token,
			},
			body: JSON.stringify({
				item,
			}),
			// successMessage: `Se registraton los días hábiles correctamente.`,
		})
		Swal.fire({
			title: '¡Acción Realizada!',
			text: 'Se registraton los días hábiles correctamente.',
			icon: 'success',
			confirmButtonText: 'OK',
		}).then((result) => {
			if (result.isConfirmed) {
				window.location.reload()
			}
		})
		return res.body
	}
}

const getSysCompanyBusinessDays = () => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(
			`/sys_company/utils/get_sys_company_business_days`,
			{
				method: 'GET',
				headers: {
					Authorization: state.app.user.token,
				},
			}
		)

		return res.body
	}
}

const actions = {
	...crud('sys_module', 'la compañía del sistema', 'las compañías del sistema'),
	validateLogoToken,
}

export default {
	...actions,
	upsertSysCompanyBusinessDays,
	getSysCompanyBusinessDays,
}
