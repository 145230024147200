import crudHandlers from "./_crud";

export default (store_parameters, action) => {
    const { type } = action;
    switch (type) {

        default:

        store_parameters = crudHandlers(store_parameters, action, 'store_parameters')

            return store_parameters
    }
}