import crudHandlers from './_crud'

const initialState = {
	entities: {},
	is_fetching: false,
	active: undefined
  }
  

export default (state = initialState, action) => {
	const { type } = action
	switch (type) {
		case 'LOAD_WIALON_UNITS': 
			return { ...state, wialonUnits: action.payload }
		case 'UPLOAD_FUEL_SUPPLY_TICKET': {
			var { response, payload } = action
			return {
				...state,
				is_fetching: false,
				fuel_supply: response,
				current_supply: payload,
			}
		}
		default:
			state = crudHandlers(state, action, 'fuel_supply')

			return state
	}
}
